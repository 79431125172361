import React, { useState, useEffect } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { LifeLine } from "react-loading-indicators";
import { CircularProgress } from "@mui/material";
import { BASE_API_URL } from "api/Api";
import Login from "layouts/authentication/sign-in";
import { useNavigate } from "react-router-dom";
import {
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
} from "@mui/material";
const Services = () => {
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [nextId, setNextId] = useState(14); // Initial nextId, adjust based on your current data
  const [editingServiceId, setEditingServiceId] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [serviceName, setServiceName] = useState("");
  const [amount, setAmount] = useState("");

  const [editedServiceName, setEditedServiceName] = useState("");
  const [editedServiceAmount, setEditedServiceAmount] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [serviceIdToDelete, setServiceIdToDelete] = useState(null);

  // Open the delete confirmation dialog
  const handleOpenDeleteDialog = (serviceId) => {
    setServiceIdToDelete(serviceId);
    setOpenDeleteDialog(true);
  };

  // Close the delete confirmation dialog
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setServiceIdToDelete(null);
  };

  // Confirm deletion if the user agrees
  const confirmDeleteService = async () => {
    if (serviceIdToDelete) {
      await handleDeleteService(serviceIdToDelete);
      handleCloseDeleteDialog();
    }
  };

  const sessionId = useSelector((state) => state.session.sessionId);

  useEffect(() => {
    const fetchServices = async () => {
      const apiUrl = BASE_API_URL;
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "SVC_Services",
        select_fields: ["service_name_c", "price_c"],
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
        const response = await axios.get(fullUrl);
        const { description, name } = response.data;
        if (description === "The session ID is invalid" && name === "Invalid Session ID") {
          navigate("/login");
        }

        const getServiceData = () => {
          if (response.data && response.data.entry_list) {
            return response.data.entry_list.map((entry) => {
              const data = entry.name_value_list;
              return {
                id: entry.id,
                name: data.service_name_c.value,
                amount: data.price_c.value,
              };
            });
          }
          return [];
        };

        const serviceData = getServiceData();
        setServices(serviceData);

        const maxId = serviceData.reduce((max, service) => Math.max(max, service.id), 0);
        setNextId(maxId + 1);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (sessionId) {
      fetchServices();
    }
  }, [sessionId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading1(true);
    if (serviceName.trim() === "" || amount.trim() === "") return;

    const newService = {
      id: nextId,
      name: serviceName,
      amount: parseFloat(amount),
    };

    setServices([...services, newService]);
    setNextId(nextId + 1);
    setServiceName("");
    setAmount("");

    const apiUrl = BASE_API_URL;
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";
    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "SVC_Services",
      name_value_list: [
        { name: "id", value: newService.id },
        { name: "service_name_c", value: newService.name },
        { name: "price_c", value: newService.amount },
      ],
    });
    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

    try {
      const response = await axios.post(fullUrl);

      if (response.status === 200 && response.data.id) {
        toast.success("Service added successfully!");
      } else {
        toast.error("Service addition failed.");
      }
    } catch (error) {
      if (error.response) {
        toast.error("An error occurred during service addition.");
      } else if (error.request) {
        toast.error("No response received from server.");
      } else {
        toast.error("Error setting up request.");
      }
    } finally {
      setLoading1(false);
    }
  };

  const handleServiceNameChange = (e) => {
    setServiceName(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleEditService = (serviceId) => {
    const serviceToEdit = services.find((service) => service.id === serviceId);
    if (serviceToEdit) {
      setEditingServiceId(serviceId);
      setEditedServiceName(serviceToEdit.name);
      setEditedServiceAmount(serviceToEdit.amount);
      setOpenModal(true);
    }
  };

  const handleSaveEditedService = async (event) => {
    event.preventDefault();
    if (editedServiceName.trim() === "" || editedServiceAmount.toString().trim() === "") return;

    const updatedServices = services.map((service) =>
      service.id === editingServiceId
        ? {
            ...service,
            name: editedServiceName,
            amount: parseFloat(editedServiceAmount),
          }
        : service
    );

    setServices(updatedServices);

    const apiUrl = BASE_API_URL;
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";
    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "SVC_Services",
      name_value_list: [
        { name: "id", value: editingServiceId },
        { name: "service_name_c", value: editedServiceName },
        { name: "price_c", value: editedServiceAmount },
      ],
    });
    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

    try {
      const response = await axios.post(fullUrl);

      if (response.status === 200 && response.data.id) {
        toast.success("Service updated successfully!");
        handleCloseModal();
      } else {
        toast.error("Service update failed.");
      }
    } catch (error) {
      if (error.response) {
        toast.error("An error occurred during service update.");
      } else if (error.request) {
        toast.error("No response received from server.");
      } else {
        toast.error("Error setting up request.");
      }
    }
  };

  const handleCloseModal = () => {
    setEditingServiceId(null);
    setEditedServiceName("");
    setEditedServiceAmount("");
    setOpenModal(false);
  };

  const handleDeleteService = async (serviceId) => {
    const apiUrl = BASE_API_URL;
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";

    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "SVC_Services",
      name_value_list: {
        id: serviceId,
        deleted: { name: "deleted", value: "1" },
      },
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

    try {
      const response = await axios.post(fullUrl);

      if (response.status === 200 && response.data.id) {
        toast.success("Service deleted successfully!");
        setServices(services.filter((service) => service.id !== serviceId));
      } else {
        toast.error("Service delete failed.");
      }
    } catch (error) {
      if (error.response) {
        toast.error("An error occurred during service delete.");
      } else if (error.request) {
        toast.error("No response received from server.");
      } else {
        toast.error("Error setting up request.");
      }
    }
  };
  const columns = [
    {
      Header: "S.No",
      accessor: "serialNumber",
    },
    { Header: "Service Name", accessor: "name" },
    { Header: "Amount", accessor: "amount" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <div>
          <IconButton
            onClick={() => handleEditService(row.original.id)}
            style={{ color: "#1976d2" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleOpenDeleteDialog(row.original.id)}
            style={{ color: "#d32f2f" }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  const rows = services
    .filter(
      (service) =>
        service.name &&
        service.name.trim() !== "" &&
        service.amount &&
        service.amount.toString().trim() !== ""
    )
    .map((service, index) => ({
      serialNumber: index + 1,
      id: service.id,
      name: service.name,
      amount: service.amount,
    }));
  if (!sessionId) {
    return <Login />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h6" color="textPrimary">
                  Add Services
                </MDTypography>
                <form onSubmit={handleSubmit}>
                  <MDBox mb={2}>
                    <MDInput
                      label="Service Name"
                      value={serviceName}
                      onChange={handleServiceNameChange}
                      fullWidth
                      required
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="number"
                      label="Amount"
                      value={amount}
                      onChange={handleAmountChange}
                      fullWidth
                      required
                    />
                  </MDBox>
                  <MDBox display="flex" justifyContent="center">
                    {loading1 ? (
                      <div className="flex justify-center">
                        <CircularProgress size={24} color="inherit" />
                      </div>
                    ) : (
                      <MDButton type="submit" variant="contained" color="primary">
                        Add Service
                      </MDButton>
                    )}
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>

          {/* Available Services Section */}
          <Grid item xs={12} md={6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="customGradient"
                borderRadius="lg"
                coloredShadow="#EA257C"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h6" color="white">
                  Available Services
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <div className="flex justify-center items-center h-80 bg-opacity-30 bg-white backdrop-blur-lg rounded-lg">
                    <LifeLine color="#9831cc" size="large" text="" textColor="" />
                  </div>
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* Edit Service Modal */}
        <Modal open={openModal} onClose={handleCloseModal}>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            p={3}
            sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <Card sx={{ width: "40%", p: 3 }}>
              <MDTypography variant="h6">Edit Service</MDTypography>
              <form onSubmit={handleSaveEditedService}>
                <MDBox mb={2}>
                  <MDInput
                    label="Service Name"
                    value={editedServiceName}
                    onChange={(e) => setEditedServiceName(e.target.value)}
                    fullWidth
                    required
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="number"
                    label="Amount"
                    value={editedServiceAmount}
                    onChange={(e) => setEditedServiceAmount(e.target.value)}
                    fullWidth
                    required
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="space-between">
                  <MDButton type="submit" variant="contained" color="primary">
                    Save
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </MDButton>
                </MDBox>
              </form>
            </Card>
          </MDBox>
        </Modal>
      </MDBox>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this service? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirmDeleteService}
            sx={{
              color: "red", // Text color
              "&:hover": {
                color: "red", // Darker red for the text on hover
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer position="top-right" autoClose={3000} />
    </DashboardLayout>
  );
};

export default Services;
