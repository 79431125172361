import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "react-data-table-component";
import Modal from "@mui/material/Modal";
import { Icon } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { BASE_API_URL } from "api/Api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { LifeLine } from "react-loading-indicators";
import Login from "layouts/authentication/sign-in";

const InPatientsLists = () => {
  const [loading, setLoading] = useState(true);
  const [editingInPatient, setEditingInPatient] = useState(null);
  const [viewingInPatient, setViewingInPatient] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [inPatients, setInPatients] = useState([]);
  const [availableServices, setAvailableServices] = useState([]);
  const [filteredInPatients, setFilteredInPatients] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [patientIdToDelete, setPatientIdToDelete] = useState(null);
  const handleOpenDeleteDialog = (patientId) => {
    setPatientIdToDelete(patientId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setPatientIdToDelete(null);
  };

  const confirmDeletePatient = async () => {
    if (patientIdToDelete) {
      await handleDeletePatient(patientIdToDelete);
      handleCloseDeleteDialog();
    }
  };

  const navigate = useNavigate();
  const sessionId = useSelector((state) => state.session.sessionId);

  useEffect(() => {
    const fetchInPatients = async () => {
      const apiUrl = BASE_API_URL;
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "Contacts",
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
        const response = await axios.get(fullUrl);
        
          const { description, name } = response.data;
          if (description === "The session ID is invalid" && name === "Invalid Session ID") {
            navigate('/login');
          }
        const getPatientsData = () => {
          if (response.data && response.data.entry_list) {
            return response.data.entry_list
              .map((entry) => {
                const data = entry.name_value_list;

                const serviceCost = parseFloat(data.servicecost_c.value) || 0;
                const totalCost = parseFloat(data.totalcost_c.value) || 0;

                const fees = serviceCost + totalCost;
                return {
                  uhid: data.record_number.value,
                  id: entry.id,

                  name: data.pateintname_c.value,
                  gender: data.patient_gender_c.value,
                  patientType: data.patienttype_c.value,
                  age: data.patient_age_c.value,
                  email: data.email1.value,
                  phone: data.phone_mobile.value,
                  city: data.city_c.value,
                  state: data.state_c.value,
                  country: data.patient_country_name_c.value,
                  admissionDate: data.admissiondate_c.value,
                  dischargedDate: data.dischargeddate_c.value,
                  service: data.servicesnames_c.value,
                  fees: fees,
                  doctor: data.doc_c.value,
                  totalCost: totalCost,
                  dateEntered: data.date_entered.value,
                };
              })
              .filter((patient) => patient.patientType.includes("In Patient"));
          }
          return [];
        };

        const inPatientsData = getPatientsData();
        setInPatients(inPatientsData);
      } catch (error) {
        toast.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchServices = async () => {
      const apiUrl = BASE_API_URL;
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "SVC_Services",
        select_fields: ["service_name_c", "price_c"],
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
        const response = await axios.get(fullUrl);

        if (response.status === 200 && response.data) {
          const { description, name } = response.data;
          if (description === "The session ID is invalid" && name === "Invalid Session ID") {
            navigate('/login');
          }

          const getServiceData = () => {
            return response.data.entry_list
              .map((entry) => {
                const data = entry.name_value_list;
                return {
                  id: entry.id,
                  name: data.service_name_c.value,
                  amount: data.price_c.value,
                };
              })
              .filter((service) => service.name);
          };

          const serviceData = getServiceData();
          setAvailableServices(serviceData);
        } else {
          toast.error("Error fetching services");
        }
      } catch (error) {
        toast.error("Unexpected API response");
      }
    };

    if (sessionId) {
      fetchInPatients();
      fetchServices();
    }
  }, [sessionId]);

  const handleDeletePatient = async (patientId) => {
    const apiUrl = `${BASE_API_URL}`;
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";

    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "Contacts",
      name_value_list: {
        id: patientId,
        deleted: { name: "deleted", value: "1" },
      },
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

    try {
      const response = await axios.post(fullUrl);
      if (response.status === 200 && response.data.id) {
        toast.success("Patient deleted Successfully");
        const updatedInPatient = inPatients.filter((patient) => patient.id !== patientId);
        setInPatients(updatedInPatient);
      } else {

        toast.error("Patient delete Failed");
      }
    } catch (error) {
      toast.error("Error during Patient Delete");
    }
  };

  const handleEditInPatient = (inPatient) => {
    navigate(`/editinpatient/${inPatient.id}`);
  };

  const handleCloseServiceModal = () => {
    setOpenServiceModal(false);
  };

  const handleServiceButtonClick = (inPatient) => {
    setEditingInPatient(inPatient);
    setSelectedServices(inPatient.service ? inPatient.service.split(", ") : []);
    setOpenServiceModal(true);
  };

  const handleSaveSelectedServices = async (patientId) => {
    const selectedServiceObjects = availableServices.filter((service) =>
      selectedServices.includes(service.name)
    );

    const totalServiceCost = selectedServiceObjects.reduce(
      (total, service) => total + parseFloat(service.amount),
      0
    );

    const otherCost = parseFloat(editingInPatient.totalCost) || 0;

    const totalFees = totalServiceCost + otherCost;

    const updatedInPatient = {
      id: patientId,
      services: selectedServices,
      serviceCost: totalServiceCost,
      totalCost: totalFees,
    };

    const apiUrl = `${BASE_API_URL}`;
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";

    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "Contacts",
      name_value_list: {
        id: updatedInPatient.id,
        servicesnames_c: updatedInPatient.services.join(", "),
        servicecost_c: updatedInPatient.serviceCost.toString(),
      },
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

    try {
      const response = await axios.post(fullUrl);

      if (response.status === 200 && response.data.id) {
        toast.success("Service updated Successfully");
        setInPatients((prevPatients) =>
          prevPatients.map((patient) =>
            patient.id === editingInPatient.id
              ? {
                  ...patient,
                  service: updatedInPatient.services.join(", "),
                  serviceCost: updatedInPatient.serviceCost,
                  fees: updatedInPatient.totalCost,
                }
              : patient
          )
        );
      } else {
        toast.error("Service Update Failed");
      }
    } catch (error) {
      toast.error("Error setting up request");
    }

    setOpenServiceModal(false);
  };

  const handleViewInPatient = (inPatient) => {
    setViewingInPatient(inPatient);
    setOpenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setViewingInPatient(null);
    setOpenViewModal(false);
  };

  const handleEditButtonClickInViewModal = () => {
    if (viewingInPatient) {
      handleEditInPatient(viewingInPatient);
      setOpenViewModal(false);
    }
  };

  useEffect(() => {
    const filteredIn = inPatients
      .filter((inPatient) => {
        const searchLower = searchTerm.toLowerCase();
        const matchesSearch =
          inPatient.uhid.toLowerCase().includes(searchLower) ||
          inPatient.name.toLowerCase().includes(searchLower) ||
          inPatient.phone.toLowerCase().includes(searchLower) ||
          inPatient.city.toLowerCase().includes(searchLower) ||
          inPatient.age.toString().includes(searchLower);

        const matchesDate = selectedDate ? inPatient.dateEntered.startsWith(selectedDate) : true;

        return matchesSearch && matchesDate;
      })
      .map((inPatient, index) => ({
        ...inPatient,
        serialNumber: index + 1,
      }));

    setFilteredInPatients(filteredIn);
  }, [searchTerm, selectedDate, inPatients]);

  const columns = [
    {
      name: "#",
      selector: (row) => (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          {row.serialNumber}
        </MDTypography>
      ),
      sortable: true,
    },
    {
      name: "UHID",
      selector: (row) => (
        <MDTypography
          component="button"
          variant="caption"
          color="text"
          fontWeight="medium"
          onClick={() => handleViewInPatient(row)}
        >
          {row.uhid}
        </MDTypography>
      ),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <MDTypography
          component="button"
          variant="caption"
          color="text"
          fontWeight="medium"
          onClick={() => handleViewInPatient(row)}
        >
          {row.name}
        </MDTypography>
      ),
      sortable: true,
    },
    {
      name: "Age",
      selector: (row) => (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          {row.age}
        </MDTypography>
      ),
      sortable: true,
    },
    {
      name: "Admission Date",
      selector: (row) => (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          {row.admissionDate}
        </MDTypography>
      ),
      sortable: true,
    },
    {
      name: "Discharge Date",
      selector: (row) => (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          {row.dischargedDate}
        </MDTypography>
      ),
      sortable: true,
    },
    {
      name: "Fees",
      selector: (row) => (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          {row.fees}
        </MDTypography>
      ),
      sortable: true,
    },
    {
      name: "Services",
      selector: (row) => (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          {row.service ? row.service : "No services added"}
        </MDTypography>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <MDBox display="flex" gap={2}>
            <MDTypography
              component="button"
              variant="caption"
              color="success"
              fontWeight="medium"
              onClick={() => handleServiceButtonClick(row)}
            >
              <Icon fontSize="small">medical_services</Icon>
            </MDTypography>
            <MDTypography
              component="button"
              variant="caption"
              color="info"
              fontWeight="medium"
              onClick={() => handleEditInPatient(row)}
            >
              <Icon fontSize="small">edit</Icon>
            </MDTypography>
            <MDTypography
              component="button"
              variant="caption"
              color="error"
              fontWeight="medium"
              onClick={() => handleOpenDeleteDialog(row.id)}
            >
              <Icon fontSize="small">delete</Icon>
            </MDTypography>
          </MDBox>
        </>
      ),
    },
  ];
  
  if (!sessionId) {
    return (
      <Login/>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="customGradient"
                borderRadius="lg"
                coloredShadow="#EA257C"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  In Patients
                </MDTypography>
                <MDBox display="flex" gap={2}>
                  <MDInput
                    label="Search"
                    className="text-white"
                    value={searchTerm}
                    inputProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <MDInput
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                  <Link to="/patientsregistration">
                    <MDButton variant="contained" color="white">
                      Add IP
                    </MDButton>
                  </Link>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <div className="flex justify-center items-center h-80 bg-opacity-30 bg-white backdrop-blur-lg rounded-lg">
                    <LifeLine color="#9831cc" size="large" text="" textColor="" />
                  </div>
                ) : (
                  <DataTable columns={columns} data={filteredInPatients} pagination />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Modal
        open={openServiceModal}
        onClose={handleCloseServiceModal}
        aria-labelledby="service-modal-title"
        aria-describedby="service-modal-description"
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            p: 4,
          }}
        >
          <MDTypography variant="h4" mb={2} gutterBottom fontWeight="bold">
            Add Services
          </MDTypography>
          <Select
            multiple
            displayEmpty
            value={selectedServices}
            onChange={(e) => setSelectedServices(e.target.value)}
            fullWidth
            className="p-3"
            renderValue={(selected) =>
              selected.length === 0 ? "Add Services" : selected.join(", ")
            }
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                  width: 250,
                },
              },
            }}
          >
            {availableServices.map((service) => (
              <MenuItem key={service.id} value={service.name}>
                <Checkbox checked={selectedServices.indexOf(service.name) > -1} />
                {service.name} - {service.amount}
              </MenuItem>
            ))}
          </Select>

          <MDBox p={2} display="flex" justifyContent="flex-end" gap={2}>
            <MDButton
              onClick={() => handleSaveSelectedServices(editingInPatient.id)}
              variant="contained"
              color="success"
            >
              Save
            </MDButton>
            <MDButton onClick={handleCloseServiceModal} variant="contained" color="error">
              Cancel
            </MDButton>
          </MDBox>
        </Card>
      </Modal>
      <Modal
        open={openViewModal}
        onClose={handleCloseViewModal}
        aria-labelledby="view-modal-title"
        aria-describedby="view-modal-description"
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            p: 4,
          }}
        >
          <MDTypography variant="h4" mb={2} gutterBottom fontWeight="bold">
            Patient Details
          </MDTypography>
          <MDTypography variant="body2" mb={1}>
            <strong>UHID:</strong> {viewingInPatient?.uhid}
          </MDTypography>
          <MDTypography variant="body2" mb={1}>
            <strong>Name:</strong> {viewingInPatient?.name}
          </MDTypography>
          <MDTypography variant="body2" mb={1}>
            <strong>Gender:</strong> {viewingInPatient?.gender}
          </MDTypography>
          <MDTypography variant="body2" mb={1}>
            <strong>Age:</strong> {viewingInPatient?.age}
          </MDTypography>
          <MDTypography variant="body2" mb={1}>
            <strong>Email:</strong> {viewingInPatient?.email}
          </MDTypography>
          <MDTypography variant="body2" mb={1}>
            <strong>City:</strong> {viewingInPatient?.city}
          </MDTypography>
          <MDTypography variant="body2" mb={1}>
            <strong>State:</strong> {viewingInPatient?.state}
          </MDTypography>
          <MDTypography variant="body2" mb={1}>
            <strong>Admission Date:</strong> {viewingInPatient?.admissionDate}
          </MDTypography>
          <MDTypography variant="body2" mb={1}>
            <strong>Discharge Date:</strong> {viewingInPatient?.dischargedDate}
          </MDTypography>
          <MDTypography variant="body2" mb={1}>
            <strong>Services:</strong> {viewingInPatient?.service}
          </MDTypography>
          <MDTypography variant="body2" mb={1}>
            <strong>Doctors:</strong> {viewingInPatient?.doctor}
          </MDTypography>
          <MDTypography variant="body2" mb={1}>
            <strong>Total Cost:</strong> {viewingInPatient?.fees}
          </MDTypography>
          <MDBox p={2} display="flex" justifyContent="flex-end" gap={2}>
            <MDButton variant="contained" color="primary" onClick={handleCloseViewModal}>
              Close
            </MDButton>
            <MDButton variant="contained" color="info" onClick={handleEditButtonClickInViewModal}>
              Edit
            </MDButton>
          </MDBox>
        </Card>
      </Modal>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this IN-Patient? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirmDeletePatient}
            sx={{
              color: "red", // Text color
              "&:hover": {
                color: "red", // Darker red for the text on hover
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer position="top-right" />
    </DashboardLayout>
  );
};

export default InPatientsLists;
