import React, { useRef, useState, useEffect } from "react";
import { Select, MenuItem, Checkbox } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import logoImage from "assets/images/HMS.webp";
import "jspdf-autotable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BASE_API_URL } from "api/Api";
import { LifeLine } from "react-loading-indicators";
import Login from "layouts/authentication/sign-in";
import "jspdf-autotable";

function Summary() {
  const [toDetails, setToDetails] = useState({
    name: "",
    age: "",
    phone: "",
    id: "",
    dateOfAdmission: "",
    dateOfSurgery: "",
    dateOfDischarge: "",
    diagnosis: "",
    consultancy: "",
    procedure: "",
    pasthistory: "",
    present: "",
    localexamination: "",
    investigation: "",
    xray: "",
    advice_discharge: "",
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [availableDoctors, setAvailableDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(
    toDetails.consultancy ? toDetails.consultancy.split(", ") : []
  );

  const sessionId = useSelector((state) => state.session.sessionId);
  useEffect(() => {
    const fetchDoctors = async () => {
      const apiUrl = BASE_API_URL;
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "dr_Doctor",
        select_fields: [
          "doctorname_c",
          "specialty_c",
          "degree_c",
          "type_c",
          "availabledays_c",
          "timing_c",
        ],
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
        const response = await axios.get(fullUrl);
        const { description, name } = response.data;
        if (description === "The session ID is invalid" && name === "Invalid Session ID") {
          navigate("/login");
        }
        if (response.status === 200 && response.data && response.data.entry_list) {
          const getDoctorData = () => {
            return response.data.entry_list.map((entry) => {
              const data = entry.name_value_list;
              return {
                id: entry.id,
                name: data.doctorname_c.value,
                specialty: data.specialty_c.value,
                degree: data.degree_c.value,
                type: data.type_c.value,
                availableDays: data.availabledays_c.value.split(","),
                timing: data.timing_c.value,
              };
            });
          };

          const doctorData = getDoctorData();

          setAvailableDoctors(doctorData);
        }
      } catch (error) {}
    };

    if (sessionId) {
      fetchDoctors();
    }
  }, [sessionId]);
  const [patients, setPatients] = useState([]);
  useEffect(() => {
    const fetchPatients = async () => {
      const apiUrl = BASE_API_URL;
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "Contacts",
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
        const response = await axios.get(fullUrl);

        const getPatientsData = () => {
          if (response.data && response.data.entry_list) {
            return response.data.entry_list
              .map((entry) => {
                const data = entry.name_value_list;

                const serviceCost = parseFloat(data.servicecost_c.value) || 0;
                const totalCost = parseFloat(data.totalcost_c.value) || 0;

                const fees = serviceCost + totalCost;
                return {
                  hospitality: "",
                  patientId: entry.id,
                  id: data.record_number.value,
                  name: data.pateintname_c.value,
                  gender: data.patient_gender_c.value,
                  patientType: data.patienttype_c.value,
                  age: data.patient_age_c.value,
                  email: data.email1.value,
                  phoneNumber: data.phone_mobile.value,
                  city: data.city_c.value,
                  state: data.state_c.value,
                  country: data.patient_country_name_c.value,
                  admissionDate: data.admissiondate_c.value,
                  dischargedDate: data.dischargeddate_c.value,
                  serviceName: data.servicesnames_c.value,
                  servicecost: data.servicecost_c.value,
                  totalAmount: fees,
                  amount: data.servicecost_c.value,
                  doctor: data.doc_c.value,
                  totalCost: totalCost,
                  dateEntered: data.date_entered.value,
                };
              })
              .filter((patient) => patient.patientType);
          }
          return [];
        };

        const PatientsData = getPatientsData();
        setPatients(PatientsData);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (sessionId) {
      fetchPatients();
    }
  }, [sessionId]);

  const handlePreviewSummary = (invoice) => {
    navigate("/dischargesummary/previewsummary", { state: { toDetails } });
    setDischargeSummary(invoice);
    setDischargeSummaryOpen(true);
  };

  const [dischargeSummary, setDischargeSummary] = useState(null);
  const [dischargeSummaryOpen, setDischargeSummaryOpen] = useState(false);

  const handlePhoneChange = (event) => {
    const value = event.target.value.trim();
    setToDetails((prevDetails) => ({ ...prevDetails, phone: value }));

    const matchedData = patients.find((item) => item.phoneNumber === value);

    if (matchedData) {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        name: matchedData.name,
        age: matchedData.age,
        patientId: matchedData.patientId,
        phone: matchedData.phoneNumber,
        id: matchedData.id,
        admissionDate: matchedData.admissionDate,
        dateOfSurgery: matchedData.dateOfSurgery,
        dischargedDate: matchedData.dischargedDate,
        diagnosis: matchedData.diagnosis,
        consultancy: matchedData.consultancy,
        procedure: matchedData.procedure,
        pasthistory: matchedData.pasthistory,
        present: matchedData.present,
        localexamination: matchedData.localexamination,
        investigation: matchedData.investigation,
        xray: matchedData.xray,
        advice_discharge: matchedData.advice_discharge,
      }));
    } else {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        phone: value,
      }));
    }
  };

  const handleIdChange = (event) => {
    const value = event.target.value.trim();

    const matchedData = patients.find((item) => item.id.toString() === value);

    if (matchedData) {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        name: matchedData.name,
        age: matchedData.age,
        phone: matchedData.phoneNumber,
        patientId: matchedData.patientId,
        id: matchedData.id,
        admissionDate: matchedData.admissionDate,
        dateOfSurgery: matchedData.dateOfSurgery,
        dischargedDate: matchedData.dischargedDate,
        diagnosis: matchedData.diagnosis,
        consultancy: matchedData.consultancy,
        procedure: matchedData.procedure,
        pasthistory: matchedData.pasthistory,
        present: matchedData.present,
        localexamination: matchedData.localexamination,
        investigation: matchedData.investigation,
        xray: matchedData.xray,
        advice_discharge: matchedData.advice_discharge,
      }));
    } else {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        id: value,
        name: "",
        age: "",
        phone: "",
        patientId: "",
        admissionDate: "",
        dateOfSurgery: "",
        dischargedDate: "",
        diagnosis: "",
        consultancy: "",
        procedure: "",
        pasthistory: "",
        present: "",
        localexamination: "",
        investigation: "",
        xray: "",
        advice_discharge: "",
      }));
    }
  };

  const invoiceRef = useRef(null);

  const handleDoctorChange = (event) => {
    const { value } = event.target;

    const updatedDoctors = Array.from(
      new Set(typeof value === "string" ? value.split(",") : value)
    );

    setSelectedDoctor(updatedDoctors);
    setToDetails((prevDetails) => ({
      ...prevDetails,
      consultancy: updatedDoctors.join(", "),
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setToDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSave = () => {
    navigate("/dischargesummary");
  };
  const handleCancel = () => {
    navigate("/dischargesummary");
  };
  if (!sessionId) {
    return <Login />;
  }
  if (loading) {
    return (
      <DashboardLayout>
        <div className="flex justify-center items-center h-80 bg-opacity-30 bg-white backdrop-blur-lg rounded-lg">
          <LifeLine color="#9831cc" size="large" text="" textColor="" />
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={14}>
            <Card>
              <Box p={4} ref={invoiceRef}>
                <Box textAlign="center" mb={3}>
                  <Typography variant="h4" fontWeight="bold" mb={2}>
                    Discharge Summary
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                  <Box>
                    <Typography variant="body1" component="div">
                      <strong>VeeraHospital</strong>
                      <br />
                      01, Silambani Sannathi Street, inside Arch Devakottai 630 302
                      <br />
                      <strong>Email:</strong> veerahospital@gmail.com
                      <br />
                      <strong>Phone-No:</strong> 98406 29905
                    </Typography>
                  </Box>
                  <Box>
                    <img src={logoImage} alt="Invoice Logo" style={{ width: "150px" }} />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={3}>
                  <Box
                    border={1}
                    borderColor="grey.400"
                    borderRadius="18px"
                    p={3}
                    width="48%"
                    bgcolor="#ffffff"
                  >
                    <Typography variant="h6" fontWeight="bold" mb={1}>
                      Patient Details
                    </Typography>
                    <div>
                      <TextField
                        label="ID"
                        name="id"
                        value={toDetails.id}
                        onChange={handleIdChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <TextField
                        label="Patient Name"
                        name="name"
                        value={toDetails.name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <TextField
                        label="Age"
                        name="age"
                        value={toDetails.age}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <TextField
                        label="Phone Number"
                        name="phone"
                        value={toDetails.phone}
                        onChange={handlePhoneChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                    </div>
                  </Box>
                  <Box
                    border={1}
                    borderColor="grey.400"
                    borderRadius="18px"
                    p={3}
                    width="48%"
                    bgcolor="#ffffff"
                  >
                    <Typography variant="h6" fontWeight="bold">
                      Admission Details
                    </Typography>
                    <div>
                      <TextField
                        label="Date Of Admission"
                        name="admissionDate"
                        value={toDetails.admissionDate || ""}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                          "& .MuiInputLabel-root": {
                            fontSize: "14px",
                          },
                        }}
                      />

                      <TextField
                        label="Date Of Surgery"
                        name="dateOfSurgery"
                        value={toDetails.dateOfSurgery || ""}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                          "& .MuiInputLabel-root": {
                            fontSize: "14px",
                          },
                        }}
                      />

                      <TextField
                        label="Date Of Discharge"
                        name="dischargedDate"
                        value={toDetails.dischargedDate || ""}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                          "& .MuiInputLabel-root": {
                            fontSize: "14px",
                          },
                        }}
                      />
                    </div>
                  </Box>
                </Box>
                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    1. Consultancy
                  </Typography>
                  <Select
                    id="doctor-select"
                    multiple
                    name="consultancy"
                    value={selectedDoctor}
                    onChange={handleDoctorChange}
                    displayEmpty
                    fullWidth
                    className="p-3"
                    renderValue={(selected) => {
                      const consultancy = toDetails?.consultancy;

                      if (selected.length === 0 && !consultancy) {
                        return "Add Doctor";
                      }

                      const selectedDoctors = selected.length < 0 ? selected.join(", ") : "";

                      return consultancy ? `${consultancy} | ${selectedDoctors}` : selectedDoctors;
                    }}
                    sx={{ mt: 1 }}
                  >
                    {availableDoctors.map((doctor) => (
                      <MenuItem key={doctor.id} value={doctor.name}>
                        <Checkbox checked={selectedDoctor.indexOf(doctor.name) > -1} />
                        {doctor.name} - {doctor.specialty}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    2. Diagnosis
                  </Typography>
                  <TextField
                    name="diagnosis"
                    multiline
                    rows={3}
                    fullWidth
                    value={toDetails.diagnosis}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#F5F5F5",
                      textDecoration: "underline",
                    }}
                  />
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    3. Procedure
                  </Typography>
                  <TextField
                    name="procedure"
                    multiline
                    rows={3}
                    fullWidth
                    value={toDetails.procedure}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: "5px", backgroundColor: "#F5F5F5" }}
                  />
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    4. Past History
                  </Typography>
                  <TextField
                    name="pasthistory"
                    multiline
                    rows={3}
                    fullWidth
                    value={toDetails.pasthistory}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: "5px", backgroundColor: "#F5F5F5" }}
                  />
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    5. Present Complaints
                  </Typography>
                  <TextField
                    name="present"
                    multiline
                    rows={3}
                    fullWidth
                    value={toDetails.present}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: "5px", backgroundColor: "#F5F5F5" }}
                  />
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    6. Investigations
                  </Typography>
                  <TextField
                    name="investigation"
                    multiline
                    rows={3}
                    fullWidth
                    value={toDetails.investigation}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: "5px", backgroundColor: "#F5F5F5" }}
                  />
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    7. Local Examination
                  </Typography>
                  <TextField
                    name="localexamination"
                    multiline
                    rows={3}
                    fullWidth
                    value={toDetails.localexamination}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: "5px", backgroundColor: "#F5F5F5" }}
                  />
                </Box>

                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    8. X-RAY
                  </Typography>
                  <TextField
                    name="xray"
                    multiline
                    rows={3}
                    fullWidth
                    value={toDetails.xray}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: "5px", backgroundColor: "#F5F5F5" }}
                  />
                </Box>
                <Box mb={3}>
                  <Typography variant="h6" fontWeight="bold">
                    9. Advice At Discharge
                  </Typography>
                  <TextField
                    name="advice_discharge"
                    multiline
                    rows={3}
                    fullWidth
                    value={toDetails.advice_discharge}
                    onChange={handleChange}
                    variant="outlined"
                    margin="normal"
                    sx={{ borderRadius: "5px", backgroundColor: "#F5F5F5" }}
                  />
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" mt={4}>
                  <Button
                    onClick={handlePreviewSummary}
                    sx={{
                      backgroundColor: "#ffff",
                      borderRadius: "16px",
                      color: "#662C90",
                      "&:hover": {
                        backgroundColor: "#662C90",
                        color: "#ffff",
                      },
                    }}
                  >
                    Preview Summary
                  </Button>

                  <Box display="flex">
                    <Button
                      onClick={handleSave}
                      sx={{
                        borderRadius: "18px",
                        backgroundColor: "green",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "darkgreen",
                          color: "#ffff",
                        },
                        marginRight: "1rem",
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => handleCancel("/dischargesummary")}
                      sx={{
                        borderRadius: "18px",
                        backgroundColor: "red",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "darkred",
                          color: "#ffff",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
}

export default Summary;
