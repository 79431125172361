import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { BASE_API_URL } from "api/Api";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { CircularProgress } from '@mui/material';
import Skeleton from "@mui/material/Skeleton"; 
import Login from "layouts/authentication/sign-in";

const EditOutPatient = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [outPatient, setOutPatient] = useState(null);
    const [doctors, setDoctors] = useState([]);
    const [availableDoctors, setAvailableDoctors] = useState([]);
    const [availableServices, setAvailableServices] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState([]); 
    const [selectedServices, setSelectedServices] = useState([]);
    const [outPatients, setOutPatients] = useState([]); 
    const [selectedPatientTypes, setSelectedPatientTypes] = useState([]);
    const sessionId = useSelector((state) => state.session.sessionId);
    const [loading, setLoading] = useState(true);
    useEffect(() => {

        const fetchOutPatients = async () => {
            const apiUrl = BASE_API_URL;
            const method = "get_entry_list";
            const input_type = "JSON";
            const response_type = "JSON";
            const rest_data = JSON.stringify({
                session: sessionId,
                module_name: "Contacts",
            });

            const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

            try {
                const response = await axios.get(fullUrl);

                const getPatientsData = () => {
                    if (response.data && response.data.entry_list) {
                        return response.data.entry_list.map(entry => {
                            const data = entry.name_value_list;
                            return {
                                id: entry.id,
                                uhid: data.record_number.value,
                                name: data.pateintname_c.value,
                                gender: data.patient_gender_c.value,
                                patientType: data.patienttype_c.value,
                                dob: data.birthdate.value,
                                age: data.patient_age_c.value,
                                email: data.email1.value,
                                phone: data.phone_mobile.value,
                                city: data.city_c.value,
                                state: data.state_c.value,
                                country: data.patient_country_name_c.value,
                                admissionDate: data.admissiondate_c.value,
                                dischargedDate: data.dischargeddate_c.value,
                                services: data.servicesnames_c.value.split(',')
                                .map(item => item.trim())
                                .filter(item => item !== ''),
                                serviceCost: data.servicecost_c.value,
                                fees: data.totalcost_c.value,
                                doctors: data.doc_c.value.split(',')
                                .map(item => item.trim())
                                .filter(item => item !== ''),
                            };
                        });
                    }else{
                        const { description, name } = response.data;
                        if (description === "The session ID is invalid" && name === "Invalid Session ID") {
                        navigate('/login');
                        }
                    }
                    return [];
                };

                const outPatientsData = getPatientsData();
                setOutPatients(outPatientsData); 
            } catch (error) {
                toast.error("Error fetching data");
            }
            finally {
                setLoading(false);
            }
        };
        const fetchServices = async () => {
            const apiUrl = BASE_API_URL;
            const method = "get_entry_list";
            const input_type = "JSON";
            const response_type = "JSON";
            const rest_data = JSON.stringify({
              session: sessionId,
              module_name: "SVC_Services",
              select_fields: ["service_name_c", "price_c"],
            });
          
            const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;
          
            try {
              const response = await axios.get(fullUrl);
              const { description, name } = response.data;
              if (description === "The session ID is invalid" && name === "Invalid Session ID") {
                navigate('/login');
              }
              if (response.status === 200 && response.data && response.data.entry_list) {
          
                const getServiceData = () => {
                  return response.data.entry_list.map((entry) => {
                    const data = entry.name_value_list;
                    return {
                      id: entry.id,
                      name: data.service_name_c.value,
                      amount: data.price_c.value,
                    };
                  })
                  .filter(service => service.name);
                };
          
                const serviceData = getServiceData();
                setAvailableServices(serviceData);
              }
            } catch (error) {
              toast.error("Error fetching services:", error);
            }
          }    
          const fetchDoctors = async () => {
            const apiUrl = BASE_API_URL;
            const method = "get_entry_list";
            const input_type = "JSON";
            const response_type = "JSON";
            const rest_data = JSON.stringify({
                session: sessionId,
                module_name: "dr_Doctor",
                select_fields: [
                    "doctorname_c",
                    "specialty_c",
                    "degree_c",
                    "type_c",
                    "availabledays_c",
                    "timing_c",
                ],
            });

            const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;
            
            try {
                const response = await axios.get(fullUrl);
                const { description, name } = response.data;
                if (description === "The session ID is invalid" && name === "Invalid Session ID") {
                  navigate('/login');
                }
                if (response.status === 200 && response.data && response.data.entry_list) {
                    const getDoctorData = () => {
                        return response.data.entry_list.map((entry) => {
                            const data = entry.name_value_list;
                            return {
                                id: entry.id,
                                name: data.doctorname_c.value,
                                specialty: data.specialty_c.value,
                                degree: data.degree_c.value,
                                type: data.type_c.value,
                                availableDays: data.availabledays_c.value.split(","),
                                timing: data.timing_c.value,
                            };
                        });
                    };

                    const doctorData = getDoctorData();
                    setDoctors(doctorData);
                }
            } catch (error) {
                toast.error("Error fetching data");
            }
        };

        if (sessionId) {
            fetchOutPatients();
            fetchServices();
            fetchDoctors();
        }
    }, [sessionId]);

    useEffect(() => {
        setAvailableDoctors(doctors || []);

        const patient = outPatients.find((patient) => patient.id === (id));
        if (patient) {
            setOutPatient(patient);
            setSelectedDoctor(patient.doctors || []); 
            setSelectedServices(patient.services || []);
            setSelectedPatientTypes(Array.isArray(patient.patientType) ? patient.patientType : [patient.patientType]);
        }
    }, [id, outPatients, doctors]);


    const handleDoctorChange = (event) => {
        const { value } = event.target;
        setSelectedDoctor(typeof value === 'string' ? value.split(',') : value);
      };
    const handleServiceChange = (event) => {
        const { value } = event.target;
        setSelectedServices(typeof value === 'string' ? value.split(',') : value);
      };

      const availablePatientTypes = [
        { id: 1, name: 'In Patient' },
        // { id: 2, name: 'Out Patient' },
      ];


      const handlePatientTypeChange = (event) => {
        const { value } = event.target;
        setSelectedPatientTypes(typeof value === 'string' ? value.split(',') : value);
    };
    const [updatingPatient, setUpdatingPatient] = useState(false);
    const handleSaveEditedOutPatient = async (event) => {
        event.preventDefault();
        setUpdatingPatient(true);

        const selectedServiceObjects = availableServices.filter(service =>
            selectedServices.includes(service.name)
          );
      
          const totalServiceCost = selectedServiceObjects.reduce((total, service) => total + parseFloat(service.amount), 0);

        // Construct the updated patient data object
        const updatedOutPatient = {
            id: outPatient.id,
            assignedDoctor: selectedDoctor,
            services: selectedServices,
            // uhid: outPatient.uhid,
            name: outPatient.name,
            gender: outPatient.gender,
            dob: outPatient.dob,
            age: outPatient.age,
            phone: outPatient.phone,
            city: outPatient.city,
            state: outPatient.country,
            email: outPatient.email,
            fees: outPatient.fees,
            serviceCost: totalServiceCost,
            patientType: selectedPatientTypes,
        };

        // API call to save the updated outpatient data
        const apiUrl = `${BASE_API_URL}`;
        const method = "set_entry";
        const input_type = "JSON";
        const response_type = "JSON";

        const rest_data = JSON.stringify({
            session: sessionId,
            module_name: "Contacts",
            name_value_list: {
                id: updatedOutPatient.id,
                // uhid_c: updatedOutPatient.uhid,
                pateintname_c: updatedOutPatient.name,
                patient_gender_c: updatedOutPatient.gender,
                birthdate: updatedOutPatient.dob,
                patient_age_c: updatedOutPatient.age,
                phone_mobile: updatedOutPatient.phone,
                city_c: updatedOutPatient.city,
                state_c: updatedOutPatient.state,
                servicesnames_c: updatedOutPatient.services.join(', '),
                servicecost_c: updatedOutPatient.serviceCost, 
                totalcost_c: updatedOutPatient.fees,
                email1: updatedOutPatient.email,
                doc_c: updatedOutPatient.assignedDoctor.join(', '),
                patienttype_c: updatedOutPatient.patientType.join(', '),
            },
        });

        const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

        try {
            const response = await axios.post(fullUrl);

            if (response.status === 200 && response.data.id) {
                setOutPatients((prevOutPatients) =>
                    prevOutPatients.map((p) =>
                        p.id === updatedOutPatient.id ? updatedOutPatient : p
                    )
                ); 
                toast.success("Patient Updated Succesfully")
            const isInpatient = selectedPatientTypes.includes('In Patient'); 
            navigate(isInpatient ? '/inpatients' : '/outpatients');
            } else {
                toast.error("Patient Update Failed")
            }
        } catch (error) {
                toast.error("Error setting up request:", error.message);
        }
        finally {
            setUpdatingPatient(false);
          }
    };
    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const ageDiff = Date.now() - birthDate.getTime();
        const ageDate = new Date(ageDiff);
        const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
        return calculatedAge; 
    };

    if (!sessionId) {
        return (
          <Login/>
        );
      }

    return (
        <DashboardLayout>
    <DashboardNavbar />
    <MDBox py={4} mx={5} px={3}>
        <MDTypography variant="h5" mb={2} gutterBottom fontWeight="bold" align="center">
            Edit Out Patients
        </MDTypography>

        {loading ? (
            <Grid container spacing={5}>
                {/* Loading Skeleton for the Left Side */}
                <Grid item xs={12} sm={6}>
                    {[...Array(6)].map((_, index) => (
                        <Skeleton key={index} variant="rectangular" height={40} sx={{ mt: 2 }} />
                    ))}
                </Grid>

                {/* Loading Skeleton for the Right Side */}
                <Grid item xs={12} sm={6}>
                    {[...Array(6)].map((_, index) => (
                        <Skeleton key={index} variant="rectangular" height={40} sx={{ mt: 2 }} />
                    ))}
                </Grid>
            </Grid>
        ) : outPatient ? (
            <Grid container spacing={5}>
                {/* Form Fields - Left Column */}
                <Grid item xs={12} sm={6}>
                    <MDInput
                        label="Name"
                        value={outPatient.name || ''}
                        onChange={(e) => setOutPatient({ ...outPatient, name: e.target.value })}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <MDInput
                        label="Date of Birth"
                        type="date"
                        value={outPatient.dob || ''}
                        onChange={(e) => {
                            const dobValue = e.target.value;
                            setOutPatient(prev => ({
                                ...prev,
                                dob: dobValue,
                                age: calculateAge(dobValue)
                            }));
                        }}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <MDInput
                        label="Age"
                        type="number"
                        value={outPatient.age || ''}
                        onChange={(e) => setOutPatient({ ...outPatient, age: e.target.value })}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <MDInput
                        label="Gender"
                        value={outPatient.gender || ''}
                        onChange={(e) => setOutPatient({ ...outPatient, gender: e.target.value })}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <MDInput
                        label="Phone Number"
                        value={outPatient.phone || ''}
                        onChange={(e) => setOutPatient({ ...outPatient, phone: e.target.value })}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <MDInput
                        label="City"
                        value={outPatient.city || ''}
                        onChange={(e) => setOutPatient({ ...outPatient, city: e.target.value })}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                </Grid>

                {/* Form Fields - Right Column */}
                <Grid item xs={12} sm={6} mt={-1}>
                    <label htmlFor="doctor-select" className="text-sm">Doctor</label>
                    <Select
                        id="doctor-select"
                        multiple
                        value={selectedDoctor}
                        onChange={handleDoctorChange}
                        displayEmpty
                        fullWidth
                        className="p-3"
                        renderValue={(selected) => (selected.length === 0 ? 'Add Doctor' : selected.join(', '))}
                        sx={{ mt: 1 }}
                        MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                    >
                        {availableDoctors.map((doctor) => (
                            <MenuItem key={doctor.id} value={doctor.name}>
                                <Checkbox checked={selectedDoctor.indexOf(doctor.name) > -1} />
                                {doctor.name} - {doctor.specialty}
                            </MenuItem>
                        ))}
                    </Select>

                    <label htmlFor="service-select" className="text-sm" style={{ marginTop: 20 }}>Services</label>
                    <Select
                        id="service-select"
                        multiple
                        value={selectedServices}
                        onChange={handleServiceChange}
                        displayEmpty
                        fullWidth
                        className="p-3"
                        renderValue={(selected) => (selected.length === 0 ? 'Add Services' : selected.join(', '))}
                        sx={{ mt: 1 }}
                    >
                        {availableServices.map((service) => (
                            <MenuItem key={service.id} value={service.name}>
                                <Checkbox checked={selectedServices.indexOf(service.name) > -1} />
                                {service.name} - {service.amount}
                            </MenuItem>
                        ))}
                    </Select>

                    <MDInput
                        label="Other Fees"
                        type="number"
                        value={outPatient.fees || ''}
                        onChange={(e) => setOutPatient({ ...outPatient, fees: e.target.value })}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <MDInput
                        label="Email"
                        value={outPatient.email || ''}
                        onChange={(e) => setOutPatient({ ...outPatient, email: e.target.value })}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <MDInput
                        label="State"
                        value={outPatient.state || ''}
                        onChange={(e) => setOutPatient({ ...outPatient, state: e.target.value })}
                        fullWidth
                        sx={{ mt: 2 }}
                    />

                    <label htmlFor="patient-type-select" className="text-sm" style={{ marginTop: 20 }}>Patient Type</label>
                    <Select
                        id="patient-type-select"
                        multiple
                        value={selectedPatientTypes}
                        onChange={handlePatientTypeChange}
                        displayEmpty
                        fullWidth
                        className="p-3"
                        renderValue={(selected) => (Array.isArray(selected) && selected.length === 0 ? 'Add Patient Type' : selected.join(', '))}
                    >
                        {availablePatientTypes.map((type) => (
                            <MenuItem key={type.id} value={type.name}>
                                <Checkbox checked={selectedPatientTypes.indexOf(type.name) > -1} />
                                {type.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
        ) : (
            <MDBox py={4} px={3}>
                <MDTypography variant="h5" fontWeight="bold">
                    No Out Patients available at the moment
                </MDTypography>
            </MDBox>
        )}

        <MDBox mt={5} mb={2} display="flex" gap="40px" justifyContent="center">
            <MDButton variant="contained" color="error" onClick={() => navigate('/outpatients')}>
                Cancel
            </MDButton>
            {updatingPatient ? (
                  <div className="flex justify-center">
                    <CircularProgress size={24} color="inherit" />
                  </div>
                ) : (
            <MDButton variant="contained" color="success" onClick={handleSaveEditedOutPatient}>
                Save
            </MDButton>
            )}
        </MDBox>
    </MDBox>

    <ToastContainer position="top-right" />
</DashboardLayout>

    );
};

export default EditOutPatient;
