import React, { useState, useEffect } from "react";
import { Link, useNavigate} from "react-router-dom";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { Icon} from "@mui/material";
import { BASE_API_URL } from "api/Api";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { LifeLine } from "react-loading-indicators";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "layouts/authentication/sign-in";

const InvoicePage = () => {
  const navigate = useNavigate();
  const sessionId = useSelector((state) => state.session.sessionId);
  const [invoices, setInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [invoiceIdToDelete, setInvoiceIdToDelete] = useState(null);
  const handleOpenDeleteDialog = (invoiceId) => {
    setInvoiceIdToDelete(invoiceId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setInvoiceIdToDelete(null);
  };

  const confirmDeleteInvoice = async () => {
    if (invoiceIdToDelete) {
      await handleDelete(invoiceIdToDelete);
      handleCloseDeleteDialog();
    }
  };

  const getInvoices = async () => {
    const apiUrl = BASE_API_URL;
    const method = "get_entry_list";
    const input_type = "JSON";
    const response_type = "JSON";
    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "Documents",
      query: "documents_cstm.type_c= 'invoice'",
    });
    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

    try {
      const response = await axios.get(fullUrl);
      const { description, name } = response.data;
      if (description === "The session ID is invalid" && name === "Invalid Session ID") {
        navigate('/login');
      }
      const getInvoicesData = () => {
        if (response.data && response.data.entry_list) {
          return response.data.entry_list.map((entry) => {
            const data = entry.name_value_list;

            return {
              id: entry.id,
              activeDate: data.active_date.value,
              doc_url: data.doc_url.value,
              uuid_c: data.uuid_c?.value || "",
              total_c: data.total_c.value,
              description: data.description?.value || "",
              type: data.type_c.value,
              invoice_num_c: data.invoice_num_c.value,
            };
          });
        }
        return [];
      };

      const invoicesData = getInvoicesData();
      setInvoices(invoicesData);
      setLoading(false);
    } catch (error) {
      toast.error("Error fetching invoice data");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sessionId) {
      getInvoices();
    }
  }, [sessionId]);

  const handleDelete = async (patientId) => {
    const apiUrl = BASE_API_URL;
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";

    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "Documents",
      name_value_list: {
        id: patientId,
        deleted: { name: "deleted", value: "1" },
      },
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

    try {
      const response = await axios.post(fullUrl);

      if (response.status === 200 && response.data.id) {

        toast.success("invoice deleted successfully!");
        setInvoices(invoices.filter((invoice) => invoice.id !== patientId));
      } else {

        toast.error("invoice delete failed.");
      }
    } catch (error) {
      if (error.response) {
        toast.error("An error occurred during invoice delete.");
      } else if (error.request) {
        toast.error("No response received from server.");
      } else {
        toast.error("Error setting up request.");
      }
    }
  };

  const filteredInvoices = Array.isArray(invoices)
    ? invoices.filter((invoice) => {
        const searchLower = searchTerm.toLowerCase();
        return (
          (invoice?.documentName?.toLowerCase() || "").includes(searchLower) ||
          (invoice?.invoiceNumber?.toLowerCase() || "").includes(searchLower) ||
          (invoice?.activeDate?.toLowerCase() || "").includes(searchLower)
        );
      })
    : [];

  const invoicesWithSerial = filteredInvoices.map((invoice, index) => ({
    ...invoice,
    serialNumber: index + 1,
  }));

  const columns = [
    {
      name: <span style={{ fontWeight: "bold" }}>S.No</span>,
      selector: (row, index) => index + 1,
      sortable: true,
      width: "140px",
      center: true,
      style: { textAlign: "center" },
    },
    {
      name: <span style={{ fontWeight: "bold" }}>ID</span>,
      selector: (row) => (
        <MDTypography
          component="a"
          href="#"
          sx={{
            color: "#3f51b5",
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          {row.uuid_c}
        </MDTypography>
      ),
      sortable: true,
      width: "150px",
      style: { textAlign: "center" },
    },
    {
      name: <span style={{ fontWeight: "bold" }}>Patient Name</span>,
      selector: (row) => row.invoice_num_c,
      sortable: true,
      width: "150px",
      style: { textAlign: "center" },
    },
    {
      name: <span style={{ fontWeight: "bold" }}>Invoice Number</span>,
      selector: (row) => row.doc_url,
      sortable: true,
      width: "150px",
      style: { textAlign: "center" },
    },
    {
      name: <span style={{ fontWeight: "bold" }}> Date</span>,
      selector: (row) => row.activeDate,
      sortable: true,
      width: "150px",
      style: { textAlign: "center" },
    },
    {
      name: <span style={{ fontWeight: "bold" }}>Phone</span>,
      selector: (row) => row.total_c,
      sortable: true,
      width: "120px",
      style: { textAlign: "center" },
    },
    {
      name: <span style={{ fontWeight: "bold" }}>Download</span>,
      cell: (row) => (
        <a
          href={row.description}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#2F855A", fontSize: "18px", display: "flex", justifyContent: "center" }}
        >
          <Icon fontSize="small">get_app</Icon>
        </a>
      ),
      width: "100px",
      center: true,
    },
    {
      name: <span style={{ fontWeight: "bold" }}>Actions</span>,
      cell: (row) => (
        <>
          <IconButton onClick={() => row.id} sx={{ color: "#3f51b5" }}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteDialog(row.id)} sx={{ color: "red" }}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
      width: "120px",
      center: true,
    },
  ];

  if (!sessionId) {
    return (
      <Login/>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card>
          <MDBox
            mx={3}
            mt={-3}
            py={2}
            px={2}
            variant="gradient"
            bgColor="customGradient"
            borderRadius="lg"
            coloredShadow="#EA257C"
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <MDTypography variant="h6" color="white">
              Invoice
            </MDTypography>
            <MDBox display="flex" gap={2}>
              <MDInput
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Link to="/createinvoice">
                <MDButton variant="contained" color="white">
                  + Create Invoice
                </MDButton>
              </Link>
            </MDBox>
          </MDBox>
          <MDBox pt={3}>
            {loading ? (
              <div className="flex justify-center items-center h-80 bg-opacity-30 bg-white backdrop-blur-lg rounded-lg">
                <LifeLine color="#9831cc" size="large" text="" textColor="" />
              </div>
            ) : (
              <DataTable columns={columns} data={invoicesWithSerial} pagination />
            )}
          </MDBox>
        </Card>
      </MDBox>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this invoice? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirmDeleteInvoice}
            sx={{
              color: "red", // Text color
              "&:hover": {
                color: "red", // Darker red for the text on hover
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer position="top-right" autoClose={3000} />
    </DashboardLayout>
  );
};

export default InvoicePage;
