import React, { useState, useEffect } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { DialogTitle, DialogContent } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { LifeLine } from "react-loading-indicators";
import { CircularProgress } from "@mui/material";
import { Button, Dialog, DialogActions, DialogContentText } from "@mui/material";
import { BASE_API_URL } from "api/Api";
import { useNavigate } from "react-router-dom";
import Login from "layouts/authentication/sign-in";
export const initialDoctors = [];

const timeOptions = [
  "12:00 AM",
  "12:30 AM",
  "01:00 AM",
  "01:30 AM",
  "02:00 AM",
  "02:30 AM",
  "03:00 AM",
  "03:30 AM",
  "04:00 AM",
  "04:30 AM",
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "08:30 AM",
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
  "08:30 PM",
  "09:00 PM",
  "09:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

const Doctors = () => {
  const [doctors, setDoctors] = useState([]);
  const navigate = useNavigate();
  const [nextId, setNextId] = useState(1);
  const [doctorName, setDoctorName] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [degree, setDegree] = useState("");
  const [type, setType] = useState("Full-time");
  const [availableDays, setAvailableDays] = useState([]);
  const [timing, setTiming] = useState([{ start: "", end: "" }]);
  const [editingDoctorId, setEditingDoctorId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [editedDoctorName, setEditedDoctorName] = useState("");
  const [editedSpecialty, setEditedSpecialty] = useState("");
  const [editedDegree, setEditedDegree] = useState("");
  const [editedType, setEditedType] = useState("Full-time");
  const [editedAvailableDays, setEditedAvailableDays] = useState([]);
  const [editedTiming, setEditedTiming] = useState([{ start: "", end: "" }]);
  const handleTimingChange = (index, field, value) => {
    const newTiming = timing.map((time, i) => (i === index ? { ...time, [field]: value } : time));
    setTiming(newTiming);
  };
  const [showAvailableDoctors, setShowAvailableDoctors] = useState(false);
  const sessionId = useSelector((state) => state.session.sessionId);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);

  const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(":");
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minute} ${period}`;
  };
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [doctorIdToDelete, setDoctorIdToDelete] = useState(null);

  const handleOpenDeleteDialog = (doctorId) => {
    setDoctorIdToDelete(doctorId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDoctorIdToDelete(null);
  };

  const confirmDeleteDoctor = async () => {
    if (doctorIdToDelete) {
      await handleDeleteDoctor(doctorIdToDelete);
      handleCloseDeleteDialog();
    }
  };

  const filterAvailableDoctors = (doctors) => {
    const currentDate = new Date();
    const currentWeekday = currentDate.getDay();
    const currentTime = convertTo12HourFormat(currentDate.toTimeString().split(" ")[0]);

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = daysOfWeek[currentWeekday];

    return doctors.filter((doctor) => {
      const isFullTime = doctor.type === "Full-time";

      if (isFullTime) {
        return true;
      }

      const isAvailableToday = doctor.availableDays.includes(today);

      if (!isAvailableToday) {
        return false;
      }
      return doctor.timing.some(({ start, end }) => {
        const startTime = convertTo12HourFormat(start);
        const endTime = convertTo12HourFormat(end);
        return currentTime >= startTime && currentTime <= endTime;
      });
    });
  };

  useEffect(() => {
    const fetchDoctors = async () => {
      const apiUrl = BASE_API_URL;
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = {
        session: sessionId,
        module_name: "dr_Doctor",
        select_fields: [
          "doctorname_c",
          "specialty_c",
          "degree_c",
          "type_c",
          "availabledays_c",
          "timing_c",
        ],
      };

      try {
        const response = await axios.get(apiUrl, {
          params: {
            method,
            input_type,
            response_type,
            rest_data: JSON.stringify(rest_data),
          },
        });

        const { description, name } = response.data;
        if (description === "The session ID is invalid" && name === "Invalid Session ID") {
          navigate("/login");
        }

        if (response.status === 200 && response.data && response.data.entry_list) {
          const getDoctorData = () => {
            return response.data.entry_list.map((entry) => {
              const data = entry.name_value_list;
              return {
                id: entry.id,
                name: data.doctorname_c.value,
                specialty: data.specialty_c.value,
                degree: data.degree_c.value,
                type: data.type_c.value,
                availableDays: data.availabledays_c.value.split(","),
                timings: data.timing_c.value,
              };
            });
          };

          const doctorData = getDoctorData().map((doctor) => ({
            ...doctor,
            timing: doctor.timings.split(",").map((t) => {
              const [start, end] = t.split("-").map((time) => time.trim());
              return { start, end };
            }),
          }));

          const filteredDoctors = showAvailableDoctors
            ? filterAvailableDoctors(doctorData)
            : doctorData;

          setDoctors(filteredDoctors);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (sessionId) {
      fetchDoctors();
    }
  }, [sessionId, showAvailableDoctors]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading1(true);

    if (
      doctorName.trim() === "" ||
      specialty.trim() === "" ||
      degree.trim() === "" ||
      type.trim() === ""
    ) {
      toast.error("All fields must be filled.");
      setLoading1(false);
      return;
    }

    const apiUrl = BASE_API_URL;
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";

    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "dr_Doctor",
      name_value_list: [
        { name: "doctorname_c", value: doctorName },
        { name: "specialty_c", value: specialty },
        { name: "degree_c", value: degree },
        { name: "type_c", value: type },
        { name: "availabledays_c", value: type === "Visiting" ? availableDays.join(",") : "" },
        {
          name: "timing_c",
          value: type === "Visiting" ? timing.map((t) => `${t.start}-${t.end}`).join(",") : "",
        },
      ],
    });

    try {
      const response = await axios.post(
        `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`
      );

      if (response.status === 200 && response.data.id) {
        const newDoctor = {
          id: nextId,
          name: doctorName,
          specialty: specialty,
          degree: degree,
          type: type,
          availableDays: type === "Visiting" ? availableDays : [],
          timing: type === "Visiting" ? timing : "",
          label: `Dr. ${doctorName} - ${specialty}`,
        };

        setDoctors([...doctors, newDoctor]);
        setNextId(nextId + 1);
        setDoctorName("");
        setSpecialty("");
        setDegree("");
        setType("Full-time");
        setAvailableDays([]);
        setTiming([]);

        toast.success("Doctor added successfully!");
      } else {
        toast.error("Failed to add doctor. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred while adding the doctor.");
    } finally {
      setLoading1(false);
    }
  };

  const handleEditDoctor = (doctorId) => {
    const doctorToEdit = doctors.find((doctor) => doctor.id === doctorId);
    if (doctorToEdit) {
      setEditingDoctorId(doctorId);
      setEditedDoctorName(doctorToEdit.name);
      setEditedSpecialty(doctorToEdit.specialty);
      setEditedDegree(doctorToEdit.degree);
      setEditedType(doctorToEdit.type);
      setEditedAvailableDays(doctorToEdit.availableDays);
      setEditedTiming(doctorToEdit.timing.length ? doctorToEdit.timing : [{ start: "", end: "" }]);
      setOpenModal(true);
      setEditedTiming(
        Array.isArray(doctorToEdit.timing) && doctorToEdit.timing.length
          ? doctorToEdit.timing
          : [{ start: "", end: "" }]
      );
      setOpenModal(true);
    }
  };

  const handleUpdateDoctor = async (event) => {
    event.preventDefault();
    if (
      editedDoctorName.trim() === "" ||
      editedSpecialty.trim() === "" ||
      editedDegree.trim() === "" ||
      editedType.trim() === ""
    ) {
      return;
    }

    const apiUrl = BASE_API_URL;
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";

    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "dr_Doctor",
      name_value_list: [
        { name: "id", value: editingDoctorId },
        { name: "doctorname_c", value: editedDoctorName },
        { name: "specialty_c", value: editedSpecialty },
        { name: "degree_c", value: editedDegree },
        { name: "type_c", value: editedType },
        {
          name: "availabledays_c",
          value: editedType === "Visiting" ? editedAvailableDays.join(",") : "",
        },
        {
          name: "timing_c",
          value:
            editedType === "Visiting"
              ? editedTiming.map((t) => `${t.start}-${t.end}`).join(",")
              : "",
        },
      ],
    });

    try {
      const response = await axios.post(
        `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`
      );

      if (response.status === 200) {
        setDoctors(
          doctors.map((doctor) =>
            doctor.id === editingDoctorId
              ? {
                  ...doctor,
                  name: editedDoctorName,
                  specialty: editedSpecialty,
                  degree: editedDegree,
                  type: editedType,
                  availableDays: editedAvailableDays,
                  timing: editedTiming,
                }
              : doctor
          )
        );
        setOpenModal(false);
        toast.success("Doctor updated successfully!");
      }
    } catch (error) {
      toast.error("Failed to update doctor.");
    }
  };

  const handleDeleteDoctor = async (doctorId) => {
    const apiUrl = BASE_API_URL;
    const method = "set_entry";
    const inputType = "JSON";
    const responseType = "JSON";

    const restData = JSON.stringify({
      session: sessionId,
      module_name: "dr_Doctor",
      name_value_list: {
        id: doctorId,
        deleted: { name: "deleted", value: "1" },
      },
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${inputType}&response_type=${responseType}&rest_data=${encodeURIComponent(
      restData
    )}`;

    try {
      const response = await axios.post(fullUrl);

      if (response.status === 200 && response.data.id) {
        toast.success("Doctor deleted successfully!");
        setDoctors((prevDoctors) => prevDoctors.filter((doctor) => doctor.id !== doctorId));
      } else {
        toast.error("Doctor delete failed.");
      }
    } catch (error) {
      handleDeleteError(error);
    }
  };

  const handleDeleteError = (error) => {
    if (error.response) {
      toast.error("An error occurred during doctor delete.");
    } else if (error.request) {
      toast.error("No response received from server.");
    } else {
      toast.error("Error setting up request.");
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const columns = [
    {
      Header: "S.No.",
      accessor: "serialNumber",
      Cell: ({ row }) => row.index + 1,
    },
    { Header: "Name", accessor: "name" },
    { Header: "Specialty", accessor: "specialty" },
    { Header: "Degree", accessor: "degree" },
    { Header: "Type", accessor: "type" },
    {
      Header: "Days",
      accessor: "availableDays",
      Cell: ({ row }) => (
        <div>{row.original.type === "Visiting" ? row.original.availableDays.join(", ") : "-"}</div>
      ),
    },
    {
      Header: "Timing",
      accessor: "timing",
      Cell: ({ row }) => (
        <div>
          {(Array.isArray(row.original.timing) ? row.original.timing : []).map((time, index) => (
            <div key={index}>
              {time.start} - {time.end}
            </div>
          ))}
        </div>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <div>
          <IconButton
            onClick={() => handleEditDoctor(row.original.id)}
            style={{ color: "#1976d2" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleOpenDeleteDialog(row.original.id)}
            style={{ color: "#d32f2f" }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  if (!sessionId) {
    return <Login />;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" gutterBottom>
                  Add Doctor
                </MDTypography>
                <MDBox component="form" onSubmit={handleFormSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        fullWidth
                        label="Name"
                        value={doctorName}
                        onChange={(e) => setDoctorName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        fullWidth
                        label="Specialty"
                        value={specialty}
                        onChange={(e) => setSpecialty(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        fullWidth
                        label="Degree"
                        value={degree}
                        onChange={(e) => setDegree(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Select
                        fullWidth
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        displayEmpty
                        sx={{ minWidth: 200, minHeight: 43 }}
                      >
                        <MenuItem value="Full-time">Full-time</MenuItem>
                        <MenuItem value="Visiting">Visiting</MenuItem>
                      </Select>
                    </Grid>
                    {type === "Visiting" && (
                      <>
                        <Grid item xs={12}>
                          <MDTypography variant="h6">Available Days</MDTypography>
                          <Select
                            multiple
                            value={availableDays}
                            onChange={(e) => setAvailableDays(e.target.value)}
                            displayEmpty
                            fullWidth
                            className="p-3"
                            renderValue={(selected) =>
                              selected.length === 0 ? "Available Days" : selected.join(", ")
                            }
                          >
                            {[
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                              "Sunday",
                            ].map((day) => (
                              <MenuItem key={day} value={day}>
                                <Checkbox checked={availableDays.indexOf(day) > -1} />
                                {day}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>

                        <Grid item xs={12}>
                          <MDTypography variant="h6">Timing</MDTypography>
                          {timing.map((time, index) => (
                            <Grid container spacing={2} key={index} alignItems="center">
                              <Grid item xs={5}>
                                <Select
                                  fullWidth
                                  value={time.start}
                                  onChange={(e) =>
                                    handleTimingChange(index, "start", e.target.value)
                                  }
                                  displayEmpty
                                  renderValue={(selected) => (selected ? selected : "Start Time")}
                                  className="p-3"
                                >
                                  {timeOptions.map((timeOption) => (
                                    <MenuItem key={timeOption} value={timeOption}>
                                      {timeOption}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                              <Grid item xs={5}>
                                <Select
                                  fullWidth
                                  value={time.end}
                                  onChange={(e) => handleTimingChange(index, "end", e.target.value)}
                                  displayEmpty
                                  className="p-3"
                                  renderValue={(selected) => (selected ? selected : "End Time")}
                                >
                                  {timeOptions.map((timeOption) => (
                                    <MenuItem key={timeOption} value={timeOption}>
                                      {timeOption}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                              <Grid item xs={2}>
                                <MDButton
                                  variant="contained"
                                  color="info"
                                  onClick={() => setTiming([...timing, { start: "", end: "" }])}
                                >
                                  Add Timing
                                </MDButton>
                                {timing.length > 1 && (
                                  <MDButton
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                      const newTiming = timing.filter((_, i) => i !== index);
                                      setTiming(newTiming);
                                    }}
                                    style={{ marginLeft: "8px" }}
                                  >
                                    Remove
                                  </MDButton>
                                )}
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      {loading1 ? (
                        <div className="flex justify-center">
                          <CircularProgress size={24} color="inherit" />
                        </div>
                      ) : (
                        <MDButton type="submit" variant="contained" color="primary">
                          Add Doctor
                        </MDButton>
                      )}
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="customGradient"
                borderRadius="lg"
                coloredShadow="#EA257C"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h6" color="white">
                  Doctors List
                </MDTypography>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showAvailableDoctors}
                        onChange={(e) => setShowAvailableDoctors(e.target.checked)}
                        sx={{
                          color: "#ffffff",
                          "&.Mui-checked": {
                            color: "#ffffff",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography variant="h6" sx={{ color: "#ffffff" }}>
                        Show Available Doctors
                      </Typography>
                    }
                    sx={{ display: "flex", alignItems: "center" }}
                  />
                </div>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <div className="flex justify-center items-center h-80 bg-opacity-30 bg-white backdrop-blur-lg rounded-lg">
                    <LifeLine color="#9831cc" size="large" text="" textColor="" />
                  </div>
                ) : (
                  <DataTable table={{ columns, rows: doctors }} isSorted={true} canSearch={true} />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Modal open={openModal} onClose={handleModalClose}>
          <div
            style={{
              padding: 3,
              backgroundColor: "white",
              margin: "20px auto",
              maxWidth: 800,
              maxHeight: "80vh",
              borderRadius: 12,
              boxShadow: 3,
              overflowY: "auto",
            }}
          >
            <DialogTitle sx={{ textAlign: "center" }}>Edit Doctor</DialogTitle>
            <DialogContent>
              <MDBox component="form" onSubmit={handleUpdateDoctor}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} sx={{ marginTop: 2 }}>
                    <MDInput
                      fullWidth
                      label="Name"
                      value={editedDoctorName}
                      onChange={(e) => setEditedDoctorName(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} sx={{ marginTop: 2 }}>
                    <MDInput
                      fullWidth
                      label="Specialty"
                      value={editedSpecialty}
                      onChange={(e) => setEditedSpecialty(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDInput
                      fullWidth
                      label="Degree"
                      value={editedDegree}
                      onChange={(e) => setEditedDegree(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      fullWidth
                      value={editedType}
                      onChange={(e) => setEditedType(e.target.value)}
                      displayEmpty
                      sx={{
                        minWidth: 200,
                        minHeight: 43,
                      }}
                    >
                      <MenuItem value="Full-time">Full-time</MenuItem>
                      <MenuItem value="Visiting">Visiting</MenuItem>
                    </Select>
                  </Grid>
                  {editedType === "Visiting" && (
                    <>
                      <Grid item xs={8.5}>
                        <MDTypography variant="h6">Available Days</MDTypography>
                        <Select
                          multiple
                          value={editedAvailableDays}
                          onChange={(e) => setEditedAvailableDays(e.target.value)}
                          displayEmpty
                          fullWidth
                          className="p-3"
                          renderValue={(selected) => {
                            if (selected.length === 0) return "Available Days";
                            if (selected.length === 1) return selected[0];

                            return (
                              selected[0] +
                              (selected.length > 1 ? " " + selected.slice(1).join(", ") : "")
                            );
                          }}
                        >
                          {[
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                            "Sunday",
                          ].map((day) => (
                            <MenuItem key={day} value={day}>
                              <Checkbox checked={editedAvailableDays.indexOf(day) > -1} />
                              {day}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <MDTypography variant="h6">Timing</MDTypography>
                        {editedTiming.map((time, index) => (
                          <Grid
                            container
                            key={index}
                            alignItems="center"
                            direction="row"
                            justifyContent="flex-start"
                            sx={{ mb: 1, gap: 3 }}
                          >
                            <Grid item xs={4} sx={{ pr: 0.5 }}>
                              {" "}
                              <Select
                                className="p-3"
                                fullWidth
                                value={time.start || ""}
                                onChange={(e) => {
                                  const newValue = e.target.value;

                                  setEditedTiming((prev) => {
                                    const newTiming = [...prev];
                                    newTiming[index].start = newValue;
                                    return newTiming;
                                  });
                                }}
                                displayEmpty
                                renderValue={(selected) => {
                                  if (!selected || selected === "") {
                                    return "Start Time";
                                  }

                                  return selected;
                                }}
                              >
                                {timeOptions.map((timeOption) => (
                                  <MenuItem key={timeOption} value={timeOption}>
                                    {timeOption}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>

                            <Grid item xs={4} sx={{ pl: 0.5 }}>
                              {" "}
                              <Select
                                className="p-3"
                                fullWidth
                                value={time.end || ""}
                                onChange={(e) => {
                                  setEditedTiming((prev) => {
                                    const newTiming = [...prev];
                                    newTiming[index].end = e.target.value;
                                    return newTiming;
                                  });
                                }}
                                displayEmpty
                                renderValue={(selected) => (selected ? selected : "End Time")}
                              >
                                {timeOptions.map((timeOption) => (
                                  <MenuItem key={timeOption} value={timeOption}>
                                    {timeOption}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>

                            <Grid item xs={3} sx={{ display: "flex", gap: 2 }}>
                              {" "}
                              <MDButton
                                variant="contained"
                                color="info"
                                onClick={() => {
                                  setEditedTiming((prevTiming) => [
                                    ...prevTiming,
                                    { start: "", end: "" },
                                  ]);
                                }}
                                sx={{
                                  fontSize: "12px",
                                  borderRadius: "18px",
                                  textAlign: "center",
                                  padding: "5px 8px",
                                  minWidth: "80px",
                                }}
                              >
                                Add Timing
                              </MDButton>
                              {editedTiming.length > 1 && (
                                <MDButton
                                  variant="contained"
                                  color="error"
                                  onClick={() => {
                                    setEditedTiming((prevTiming) =>
                                      prevTiming.filter((_, i) => i !== index)
                                    );
                                  }}
                                  sx={{
                                    fontSize: "12px",
                                    borderRadius: "18px",
                                    textAlign: "center",
                                    padding: "5px 8px",
                                    minWidth: "80px",
                                  }}
                                >
                                  Remove
                                </MDButton>
                              )}
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  )}
                  <Grid container item xs={12}>
                    <Grid item xs={2.5}>
                      <MDButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ borderRadius: "32px" }}
                      >
                        Update Doctor
                      </MDButton>
                    </Grid>

                    <Grid item xs={9.5} sx={{ textAlign: "right" }}>
                      <MDButton
                        onClick={handleModalClose}
                        variant="outlined"
                        color="secondary"
                        fullWidth={false}
                        sx={{
                          borderRadius: "32px",
                          backgroundColor: "#662C90",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "#662C90",
                          },
                        }}
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </DialogContent>
          </div>
        </Modal>
      </MDBox>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this doctor? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirmDeleteDoctor}
            sx={{
              color: "red", // Text color
              "&:hover": {
                color: "red", // Darker red for the text on hover
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer position="top-right" />
    </DashboardLayout>
  );
};
export default Doctors;
