import React, { useRef, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import logoImage from "assets/images/HMS.webp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import axios from "axios";
import { BASE_API_URL, INVOICE_API_URL } from "api/Api";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LifeLine } from "react-loading-indicators";
import { CircularProgress } from "@mui/material";
import Login from "layouts/authentication/sign-in";
import { useNavigate} from "react-router-dom";

function Invoice() {
  const [patients, setPatients] = useState([]);
  const [availableServices, setAvailableServices] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const navigate = useNavigate();
  const sessionId = useSelector((state) => state.session.sessionId);
  const [loading, setLoading] = useState(true);
  const [postInvoice, setPostInvoice] = useState(false);

  useEffect(() => {
    const fetchPatients = async () => {
      const apiUrl = BASE_API_URL;
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "Contacts",
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
        const response = await axios.get(fullUrl);
        const { description, name } = response.data;
        if (description === "The session ID is invalid" && name === "Invalid Session ID") {
          navigate('/login');
        }

        const getPatientsData = () => {
          if (response.data && response.data.entry_list) {
            return response.data.entry_list
              .map((entry) => {
                const data = entry.name_value_list;

                const serviceCost = parseFloat(data.servicecost_c.value) || 0;
                const totalCost = parseFloat(data.totalcost_c.value) || 0;

                const fees = serviceCost + totalCost;
                return {
                  hospitality: "",
                  patientId: entry.id,
                  id: data.record_number.value,
                  name: data.pateintname_c.value,
                  gender: data.patient_gender_c.value,
                  patientType: data.patienttype_c.value,
                  age: data.patient_age_c.value,
                  email: data.email1.value,
                  phoneNumber: data.phone_mobile.value,
                  city: data.city_c.value,
                  state: data.state_c.value,
                  country: data.patient_country_name_c.value,
                  admissionDate: data.admissiondate_c.value,
                  dischargedDate: data.dischargeddate_c.value,
                  serviceName: data.servicesnames_c.value,
                  servicecost: data.servicecost_c.value,
                  totalAmount: fees,
                  amount: data.servicecost_c.value,
                  doctor: data.doc_c.value,
                  totalCost: totalCost,
                  dateEntered: data.date_entered.value,
                };
              })
              .filter((patient) => patient.patientType);
          }
          return [];
        };

        const PatientsData = getPatientsData();
        setPatients(PatientsData);
      } catch (error) {
        toast.error("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    const fetchServices = async () => {
      const apiUrl = "https://veerahospital.com/billing-software/service/v4_1/rest.php";
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "SVC_Services",
        select_fields: ["service_name_c", "price_c"],
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
        const response = await axios.get(fullUrl);
        const { description, name } = response.data;
        if (description === "The session ID is invalid" && name === "Invalid Session ID") {
          navigate('/login');
        }
        if (response.status === 200 && response.data && response.data.entry_list) {

          const getServiceData = () => {
            return response.data.entry_list
              .map((entry) => {
                const data = entry.name_value_list;
                return {
                  id: entry.id,
                  name: data.service_name_c.value,
                  amount: data.price_c.value,
                };
              })
              .filter((service) => service.name);
          };

          const serviceData = getServiceData();
          setAvailableServices(serviceData);
        } else {
          toast.error("Error fetching services");
        }
      } catch (error) {
        toast.error("Unexpected API response");
      }
    };

    if (sessionId) {
      fetchPatients();
      fetchServices();
    } 
  }, [sessionId]);

  const invoiceRef = useRef();

  const [invoiceData, setInvoiceData] = useState({
    number: "",
    date: "",
  });

  const [toDetails, setToDetails] = useState({
    name: "",
    city: "",
    phone: "",
    id: "",
    date: "",

    serviceName: "",
    hospitality: "",
    amount: "",
    totalAmount: "",
  });

  const [items, setItems] = useState([
    { serviceName: "", hospitality: "", amount: "", totalAmount: "" },
  ]);

  const [savedItems, setSavedItems] = useState([]);
  const [showAddItemForm, setShowAddItemForm] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const handlePhoneChange = (event) => {
    const { value } = event.target;
    setToDetails((prevDetails) => ({ ...prevDetails, phone: value }));

    const matchedData = patients.find((item) => item.phoneNumber === value);
    if (matchedData) {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        patientId: matchedData.patientId,
        name: matchedData.name,
        city: matchedData.city,
        phone: matchedData.phoneNumber,
        id: matchedData.id,
        date: matchedData.date,
        serviceName: matchedData.serviceName,
        hospitality: matchedData.hospitality,
        otherFees: matchedData.totalCost,
        amount: matchedData.amount,
        totalAmount: matchedData.totalAmount,
      }));
    } else {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        phone: value,
      }));

      patients.push({
        id: patients.length + 1,
        name: "",
        city: "",
        phoneNumber: value,
        email: "",
        invoiceDate: new Date().toISOString().split("T")[0],
        totalAmount: 0,
        otherFees: 0,
        status: "Pending",
        uhid: "",
        doctorName: "",
      });
    }
  };

  const handleIdChange = (event) => {
    const { value } = event.target;
    setToDetails((prevDetails) => ({ ...prevDetails, id: value }));

    const matchedData = patients.find((item) => item.id.toString() === value);
    if (matchedData) {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        patientId: matchedData.patientId,
        otherFees: matchedData.totalCost,
        name: matchedData.name,
        city: matchedData.city,
        phone: matchedData.phoneNumber,
        id: matchedData.id,
        date: matchedData.date,
        serviceName: matchedData.serviceName,
        hospitality: matchedData.hospitality,
        amount: matchedData.amount,
        totalAmount: matchedData.totalAmount,
      }));
    } else {
      setToDetails((prevDetails) => ({
        ...prevDetails,
      }));

      patients.push({
        id: value,
        name: "",
        city: "",
        phoneNumber: "",
        email: "",
        invoiceDate: new Date().toISOString().split("T")[0],
        totalAmount: 0,
        otherFees: 0,
        status: "Pending",
        uhid: "",
        doctorName: "",
      });
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name in invoiceData) {
      setInvoiceData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name.includes("-")) {
      const [itemIndex, fieldName] = name.split("-");
      setItems((prevItems) => {
        const newItems = [...prevItems];

        if (newItems[itemIndex]) {
          newItems[itemIndex][fieldName] = value;
        } 

        return newItems;
      });
    } else if (name in toDetails) {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    } 
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [editType, setEditType] = useState("");
  const [editIndex, setEditIndex] = useState(null);

  const handleEditItem = (item, type, index = null) => {
    setEditData(item);
    setEditType(type);
    setEditIndex(index);
    setIsModalOpen(true);
  };

  const handleEditChange = (field, value) => {
    setEditData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleDeleteToDetails = () => {
    setToDetails({});
    setToDetails((prev) => ({
      ...prev,
      id: "",
      phone: "",
      city: "",
      name: "",
    }));
  };

  useEffect(() => {
    const newServices = toDetails.serviceName ? toDetails.serviceName.split(",") : [];
    setServices(newServices);
  }, [toDetails]);

  const filteredAvailableServices = availableServices.filter(
    (service) => !services.includes(service.name)
  );

  const handleServiceChange = (event) => {
    const { value } = event.target;
    setSelectedServices(typeof value === "string" ? value.split(",") : value);
  };

  const [fees, setFees] = useState("0");

  const handleFeeChange = (e) => {
    setFees(e.target.value);
  };
  const selectedServiceObjects = availableServices.filter((service) =>
    selectedServices.includes(service.name)
  );

  const totalServiceCost = selectedServiceObjects.reduce(
    (total, service) => total + parseFloat(service.amount),
    0
  );

  const totalfees = parseFloat(fees) + totalServiceCost;

  const startingIndex = toDetails.city || toDetails.id ? 2 : 1;

  let combinedServices = selectedServices.length > 0 ? `${services},${selectedServices}` : services;

  let serviceCost = parseFloat(totalServiceCost) + parseFloat(toDetails.amount);

  let totalCost = parseFloat(fees) + parseFloat(toDetails.otherFees);

  const updatedPatient = {
    id: toDetails.patientId,
    services: combinedServices,
    serviceCost: serviceCost,
    totalCost: totalCost,
  };

  const updateServices = async () => {
    const apiUrl = `${BASE_API_URL}`;
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";

    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "Contacts",
      name_value_list: {
        id: updatedPatient.id,
        servicesnames_c: updatedPatient.services,
        servicecost_c: updatedPatient.serviceCost.toString(),
        totalcost_c: updatedPatient.totalCost.toString(),
      },
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

    try {
      const response = await axios.post(fullUrl);

      if (response.status === 200 && response.data.id) {
        toast.success("Service updated successfully");
      } else {
        toast.error("Service update failed");
      }
    } catch (error) {
 
        toast.error("Error during Service update");
    
    }
  };

  const handleSave = () => {
    setSavedItems(items);
    setItems([{ serviceName: "", hospitality: "", amount: "", totalAmount: "" }]);
    setShowAddItemForm(false);
  };

  const handleSaveEdit = () => {
    if (editType === "details") {
      setToDetails(editData);
    } else if (editType === "saved" && editIndex !== null) {
      const updatedItems = [...savedItems];
      updatedItems[editIndex] = editData;
      setSavedItems(updatedItems);
    }
    setIsModalOpen(false);
  };
  const handleDeleteItem = (index) => {
    setSavedItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };
  const handlePreview = () => {
    setOpenPreviewModal(true);
  };

  const grandTotal = (toDetails.totalAmount || 0) + (totalfees || 0);

  const handleDownload = async () => {
    setPostInvoice(true);
    const canvas = await html2canvas(document.querySelector("#invoiceCaptured"), { scale: 4 });
    const imgData = canvas.toDataURL("image/png", 1);
    updateServices();

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
      compress: true,
    });

    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const ratio = Math.min(pdfWidth / imgProps.width, pdfHeight / imgProps.height);
    const imgWidth = imgProps.width * ratio;
    const imgHeight = imgProps.height * ratio;

    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

    const base64PDF = pdf.output("datauristring").split(",")[1];

    const pdfName = `${toDetails.name}-${invoiceData.number}-${invoiceData.date}.pdf`;

    pdf.save(pdfName);

    const payload = {
      type: "invoice",
      filename: pdfName,
      contact: toDetails.patientId,
      date: invoiceData.date,
      inv: invoiceData.number,
      uuid: toDetails.id,
      name: toDetails.name,
      total: toDetails.phone,
      file: base64PDF,
    };

    try {
      const response = await axios.post(INVOICE_API_URL, payload);
      if (response.status === 200) {

        toast.success("Service Stored Successfully");
      } else {
        
        toast.error("Invoice Storing Failed");
      }
    } catch (error) {
      toast.error("Error saving invoice");
    } finally {
      setPostInvoice(false);
    }
  };

  if (!sessionId) {
    return (
      <Login/>
    );
  }

  if (loading) {
    return (
      <DashboardLayout>
        <div className="flex justify-center items-center h-80 bg-opacity-30 bg-white backdrop-blur-lg rounded-lg">
          <LifeLine color="#9831cc" size="large" text="" textColor="" />
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={14}>
            <Card>
              <Box p={4} ref={invoiceRef}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                  <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Box mr={2}>
                      <TextField
                        label="Invoice Number"
                        name="number"
                        type="number"
                        size="small"
                        value={invoiceData.number}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        sx={{ borderRadius: "18px", width: "190px" }}
                      />
                    </Box>
                    <Box>
                      <TextField
                        label="Date"
                        name="date"
                        type="date"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={invoiceData.date}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        sx={{ borderRadius: "18px", width: "190px" }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <img
                      src={logoImage}
                      alt="Invoice Logo"
                      style={{ width: "150px", float: "right" }}
                    />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={3}>
                  <Box
                    border={1}
                    borderColor="grey.400"
                    borderRadius="18px"
                    p={3}
                    width="48%"
                    bgcolor="#ffffff"
                  >
                    <Typography variant="h6" fontWeight="bold" mb={1}>
                      From
                    </Typography>
                    <Typography variant="body1" fontWeight="bold" gutterBottom>
                      VeeraHospital
                    </Typography>
                    <Typography variant="body2" fontWeight="normal" gutterBottom>
                      01, Silambani Sannathi Street, inside Arch Devakottai 630 302
                    </Typography>
                    <Typography variant="body2" fontWeight="normal" gutterBottom>
                      <strong>Email:</strong> veerahospital@gmail.com
                    </Typography>
                    <Typography variant="body2" fontWeight="normal">
                      <strong>Phone-No:</strong> 98406 29905
                    </Typography>
                  </Box>
                  <Box
                    border={1}
                    borderColor="grey.400"
                    borderRadius="18px"
                    p={3}
                    width="48%"
                    bgcolor="#ffffff"
                  >
                    <Typography variant="h6" fontWeight="bold">
                      To
                    </Typography>
                    <div>
                      <TextField
                        label="ID"
                        name="id"
                        value={toDetails.id}
                        onChange={handleIdChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <TextField
                        label="Patient Name"
                        name="name"
                        value={toDetails.name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <TextField
                        label="City"
                        name="city"
                        value={toDetails.city}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <TextField
                        label="Phone Number"
                        name="phone"
                        value={toDetails.phone}
                        onChange={handlePhoneChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                    </div>
                  </Box>
                </Box>

                <Box mt={3}>
                  <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <DialogTitle sx={{ textAlign: "center" }}>Edit Item</DialogTitle>
                    <DialogContent>
                      <TextField
                        fullWidth
                        margin="dense"
                        label="Date"
                        value={editData?.date || ""}
                        onChange={(e) => handleEditChange("date", e.target.value)}
                      />
                      <Select
                        multiple
                        displayEmpty
                        value={selectedServices}
                        onChange={(e) => setSelectedServices(e.target.value)}
                        fullWidth
                        className="p-3"
                        renderValue={(selected) =>
                          selected.length === 0 ? "Add Services" : selected.join(", ")
                        }
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 224,
                              width: 250,
                            },
                          },
                        }}
                      >
                        {filteredAvailableServices.map((service) => (
                          <MenuItem key={service.id} value={service.name}>
                            <Checkbox checked={selectedServices.indexOf(service.name) > -1} />
                            {service.name} - {service.amount}
                          </MenuItem>
                        ))}
                      </Select>
                      <TextField
                        fullWidth
                        margin="dense"
                        label="Hospitality"
                        value={editData?.hospitality || ""}
                        onChange={(e) => handleEditChange("hospitality", e.target.value)}
                      />
                      <TextField
                        fullWidth
                        margin="dense"
                        label="Service Cost"
                        value={totalServiceCost}
                      />
                      <TextField
                        fullWidth
                        margin="dense"
                        label="Other Fee"
                        value={fees}
                        onChange={handleFeeChange}
                      />
                      <TextField fullWidth margin="dense" label="Total Amount" value={totalfees} />
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center" }}>
                      <Button
                        onClick={() => setIsModalOpen(false)}
                        sx={{
                          color: "#ffff",
                          backgroundColor: "red",
                          "&:hover": { backgroundColor: "darkred" },
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleSaveEdit}
                        sx={{
                          color: "#ffff",
                          backgroundColor: "green",
                          "&:hover": { backgroundColor: "darkgreen" },
                        }}
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(8, 1fr)"
                    gap={2}
                    bgcolor="#662C90"
                    borderRadius="8px"
                    p={1}
                    mb={3}
                  >
                    <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                      S.NO
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                      Date
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                      Service
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                      Hospitality
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                      Amount
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                      Total Amount
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                      Action
                    </Typography>
                  </Box>

                  {toDetails.city || toDetails.id ? (
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(8, 1fr)"
                      gap={2}
                      p={2}
                      borderBottom={1}
                      borderColor="grey.300"
                    >
                      <Typography variant="body1">1</Typography>
                      <Typography variant="body1">{invoiceData.date}</Typography>
                      <Typography variant="body1">{toDetails.serviceName}</Typography>
                      <Typography variant="body1">{toDetails.hospitality}</Typography>
                      <Typography variant="body1">{toDetails.amount}</Typography>
                      <Typography variant="body1">{toDetails.totalAmount}</Typography>

                      <Box display="flex" justifyContent="flex-start" gap={1}>
                        <Button
                          onClick={() => handleEditItem(toDetails, "details")}
                          sx={{ minWidth: "auto", padding: "4px" }}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          onClick={handleDeleteToDetails}
                          sx={{ minWidth: "auto", padding: "4px" }}
                        >
                          <DeleteIcon />
                        </Button>
                      </Box>
                    </Box>
                  ) : null}

                  {savedItems.map((item, index) => (
                    <Box
                      key={index}
                      display="grid"
                      gridTemplateColumns="repeat(8, 1fr)"
                      gap={2}
                      p={2}
                      borderBottom={1}
                      borderColor="grey.300"
                    >
                      <Typography>{startingIndex + index}</Typography>
                      <Typography>{item.date}</Typography>
                      <Typography>{selectedServices}</Typography>
                      <Typography>{item.hospitality}</Typography>
                      <Typography>{totalServiceCost}</Typography>
                      <Typography>{totalfees}</Typography>
                      <Box display="flex" justifyContent="flex-start" gap={1}>
                        <Button
                          onClick={() => handleEditItem(item, "saved", index)}
                          sx={{ minWidth: "auto", padding: "4px" }}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          onClick={() =>{
                            setFees(0);
                            handleDeleteItem(index);}}
                          sx={{ minWidth: "auto", padding: "4px" }}
                        >
                          <DeleteIcon />
                        </Button>
                      </Box>
                           
                    </Box>
                  ))}
                  <Box
                      display="grid"
                      gridTemplateColumns="repeat(8, 1fr)"
                      gap={2}
                      p={2}
                      borderBottom={1}
                      borderColor="grey.300"
                    >
                        <Typography ></Typography> {/* Empty for alignment */}
                        <Typography ></Typography> {/* Empty for alignment */}
                        <Typography ></Typography> {/* Empty for alignment */}
                        <Typography ></Typography> {/* Empty for alignment */}
                        <Typography >Total:</Typography>
                        <Typography >{grandTotal}</Typography>
                    </Box>
                </Box>
                {showAddItemForm && (
                  <Box>
                    {items.map((item, index) => (
                      <Box
                        key={index}
                        mb={0}
                        p={2}
                        border={1}
                        borderColor="grey.300"
                        borderRadius="8px"
                        bgcolor="#F9F9F9"
                      >
                        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
                          <TextField
                            label="Date"
                            name={`${index}-date`}
                            type="date"
                            value={item.date}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            sx={{
                              borderRadius: "18px",
                              backgroundColor: "#D8F5EE",
                              "& .MuiOutlinedInput-root": { borderRadius: "18px", height: "45px" },
                            }}
                          />
                          <TextField
                            fullWidth
                            margin="normal"
                            label="Other Fee"
                            value={fees}
                            onChange={handleFeeChange}
                            sx={{
                              borderRadius: "18px",
                              backgroundColor: "#D8F5EE",
                              "& .MuiOutlinedInput-root": { borderRadius: "18px", height: "45px" },
                            }}
                          />
                          <Select
                            multiple
                            displayEmpty
                            value={selectedServices}
                            onChange={handleServiceChange}
                            fullWidth
                            className="p-1 m-2"
                            renderValue={(selected) =>
                              selected.length === 0 ? "Add Services" : selected.join(", ")
                            }
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 224,
                                  width: 250,
                                },
                              },
                            }}
                            sx={{
                              borderRadius: "18px",
                              backgroundColor: "#D8F5EE",
                              "& .MuiOutlinedInput-root": { borderRadius: "18px", height: "55px" },
                            }}
                          >
                            {filteredAvailableServices.map((service) => (
                              <MenuItem key={service.id} value={service.name}>
                                <Checkbox checked={selectedServices.indexOf(service.name) > -1} />
                                {service.name} - {service.amount}
                              </MenuItem>
                            ))}
                          </Select>
                          <TextField
                            label="Hospitality"
                            name={`${index}-hospitality`}
                            value={item.hospitality}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            sx={{
                              borderRadius: "18px",
                              backgroundColor: "#D8F5EE",
                              "& .MuiOutlinedInput-root": { borderRadius: "18px", height: "45px" },
                            }}
                          />
                          <TextField
                            label="Service Cost"
                            name={`${index}-amount`}
                            type="number"
                            value={totalServiceCost}
                            fullWidth
                            margin="normal"
                            sx={{
                              borderRadius: "18px",
                              backgroundColor: "#D8F5EE",
                              "& .MuiOutlinedInput-root": { borderRadius: "18px", height: "45px" },
                            }}
                          />
                          <TextField
                            label="TotalAmount"
                            name={`${index}-totalAmount`}
                            type="number"
                            value={totalfees}
                            fullWidth
                            margin="normal"
                            sx={{
                              borderRadius: "18px",
                              backgroundColor: "#D8F5EE",
                              "& .MuiOutlinedInput-root": { borderRadius: "18px", height: "45px" },
                            }}
                          />
                        </Box>
                      </Box>
                    ))}

                    <Box display="flex" justifyContent="flex-end" mt={2}>
                      <Button
                        variant="gradient"
                        color="primary"
                        onClick={handleSave}
                        sx={{
                          borderRadius: "18px",
                          color: "#FFFFFF",
                          backgroundColor: "#662C90",
                          padding: "9px",
                          marginRight: "8px",
                          "&:hover": {
                            backgroundColor: "#5a257e",
                          },
                        }}
                      >
                        Save Items
                      </Button>
                      <Button
                        variant="gradient"
                        color="secondary"
                        onClick={() => {
                          setFees(0);
                          setShowAddItemForm(false);
                        }}
                        sx={{
                          borderRadius: "18px",
                          color: "#FFFFFF",
                          backgroundColor: "#F44336",
                          padding: "10px",
                          "&:hover": {
                            backgroundColor: "#F44336",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                )}
                <Box mt={3} display="flex" justifyContent="space-between">
                  <Button
                    variant="gradient"
                    color="primary"
                    onClick={handlePreview}
                    sx={{
                      color: "#FFFFFF",
                      borderRadius: "18px",
                      backgroundColor: "#662C90",
                      padding: "9px",
                      marginRight: "492px",
                      "&:hover": {
                        backgroundColor: "#5a257e",
                      },
                    }}
                  >
                    Preview Invoice
                  </Button>
                  {showAddItemForm ? null : (
                    <Button
                      variant="gradient"
                      color="primary"
                      onClick={() => setShowAddItemForm(true)}
                      sx={{
                        borderRadius: "18px",
                        color: "#FFFFFF",
                        backgroundColor: "#662C90",
                        padding: "10px",
                        marginleft: "492px",
                        "&:hover": {
                          backgroundColor: "#5a257e",
                        },
                      }}
                    >
                      Add Item
                    </Button>
                  )}
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box
        mt={1}
        p={-1}
        border={2}
        borderColor="white"
        borderRadius="18px"
        bgcolor="#FFFFFF"
        position="relative"
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{
            position: "absolute",
            top: -10,
            left: 10,
            bgcolor: "#FFFFFF",
            px: 1,
            borderRadius: "18px",
            fontSize: "1rem",
          }}
        >
          Notes:
        </Typography>
        <Box mt={2} p={1}>
          <Typography variant="body2" sx={{ fontSize: "0.895rem", fontWeight: "bold" }}>
            All accounts are to be paid within 7 days from receipt of invoice. To be paid by cheque
            or credit card or direct payment online.
          </Typography>
        </Box>
      </Box>
      <Dialog
        open={openPreviewModal}
        onClose={() => setOpenPreviewModal(false)}
        fullWidth
        maxWidth="md"
      >
        <div id="invoiceCaptured">
          <DialogTitle
            sx={{
              textAlign: "left",
              padding: "10px 25px",
              backgroundColor: "#f5f5f5",
              color: "#662C90",
              borderBottom: "1px solid #e0e0e0",
              fontSize: "1.5rem",
              fontWeight: "bold",
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Box
              component="img"
              sx={{
                height: 50,
                marginLeft: 2,
                marginRight: 4,
              }}
              alt="Veera Logo"
              src={logoImage}
            />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#662C90", fontSize: "1.3rem" }}
              >
                VEERA HOSPITAL
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", fontSize: "0.85rem", color: "#141414" }}
              >
                Orthopedic Hospital
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", color: "#141414", fontSize: "0.85rem" }}
              >
                01, Silambani Sannathi Street, inside Arch, Devakottai 630 302
              </Typography>
              <Typography variant="body2" sx={{ color: "#141414", fontSize: "0.85rem" }}>
                <strong>Email:</strong> veerahospital@gmail.com | <strong>Phone-No:</strong> 98406
                29905 |<strong>Hours:</strong> Open 24 hours
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className="flex justify-center mt-2 mb-1">
              <Typography variant="h5" fontWeight="bold">
                Invoice
              </Typography>
            </Box>
            <Box pl={3} pr={3}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6" fontWeight="bold">
                  Invoice Number:{" "}
                  <Typography variant="body2" component="span" fontWeight="normal">
                    {invoiceData.number}
                  </Typography>
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  Date:{" "}
                  <Typography variant="body2" component="span" fontWeight="normal">
                    {invoiceData.date}
                  </Typography>
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-start"
                mb={1}
                mt={0.5}
              >
                <Grid container spacing={0.5} mb={1} mt={0.5}>

                  <Grid container item spacing={1} alignItems="center">
                    <Grid item xs={2.5} style={{ textAlign: 'right' }}>
                      <Typography variant="h6" component="span">
                        <strong>Patient ID:</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={9} style={{ textAlign: 'left' }}>
                      <Typography variant="body2" component="span">
                        {toDetails.id}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item spacing={1} alignItems="center">
                    <Grid item xs={2.5} style={{ textAlign: 'right' }}>
                      <Typography variant="h6" component="span">
                        <strong>Name:</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={9} style={{ textAlign: 'left' }}>
                      <Typography variant="body2" component="span">
                        {toDetails.name}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item spacing={1} alignItems="center">
                    <Grid item xs={2.5} style={{ textAlign: 'right' }}>
                      <Typography variant="h6" component="span">
                        <strong>Phone-No:</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={9} style={{ textAlign: 'left' }}>
                      <Typography variant="body2" component="span">
                        {toDetails.phone}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item spacing={1} alignItems="center">
                    <Grid item xs={2.5} style={{ textAlign: 'right' }}>
                      <Typography variant="h6" component="span">
                        <strong>City:</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={9} style={{ textAlign: 'left' }}>
                      <Typography variant="body2" component="span">
                        {toDetails.city}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Box mt={1}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(6, 1fr)"
                  gap={1.5}
                  bgcolor="#662C90"
                  borderRadius="8px"
                  p={1}
                  mb={0.5}
                >
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    style={{ color: "#ffffff", fontSize: "0.85rem" }}
                  >
                    S.NO
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    style={{ color: "#ffffff", fontSize: "0.85rem" }}
                  >
                    Date
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    style={{ color: "#ffffff", fontSize: "0.85rem" }}
                  >
                    Service
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    style={{ color: "#ffffff", fontSize: "0.85rem" }}
                  >
                    Hospitality
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    style={{ color: "#ffffff", fontSize: "0.85rem" }}
                  >
                    Amount
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    style={{ color: "#ffffff", fontSize: "0.85rem" }}
                  >
                    Total Amount
                  </Typography>
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(6, 1fr)"
                  gap={1.5}
                  p={0.5}
                  borderBottom={1}
                  borderColor="grey.300"
                >
                  {toDetails.city || toDetails.id ? (
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold", marginRight: "20px", fontSize: "0.85rem" }}
                    >
                      1
                    </Typography>
                  ) : null}

                  <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                    {invoiceData.date}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                    {toDetails.serviceName}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                    {toDetails.hospitality}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                    {toDetails.amount}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                    {toDetails.totalAmount}
                  </Typography>
                </Box>
                {savedItems.map((item, index) => (
                  <Box
                    key={index}
                    display="grid"
                    gridTemplateColumns="repeat(6, 1fr)"
                    gap={1.5}
                    p={0.5}
                    borderBottom={1}
                    borderColor="grey.300"
                  >
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                      {startingIndex + index}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                      {item.date}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                      {selectedServices}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                      {item.hospitality}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                      {totalServiceCost}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                      {totalfees}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {/* Calculate Overall Amounts */}
              <Box
                  display="grid"
                  gridTemplateColumns="repeat(6, 1fr)"
                  gap={1.5}
                  p={0.5}
                  mt={2}
                  fontWeight="bold"
              >
                  <Typography variant="body2"></Typography> {/* Empty for alignment */}
                  <Typography variant="body2"></Typography> {/* Empty for alignment */}
                  <Typography variant="body2"></Typography> {/* Empty for alignment */}
                  <Typography variant="body2"></Typography> {/* Empty for alignment */}
                  <Typography variant="body2"  sx={{ fontWeight: "bold", fontSize: "0.9rem" }}>Total:</Typography>
                  <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.9rem" }}>
                  {grandTotal}
                  </Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "50px",
                marginRight: "30px",
              }}
            >
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#141414",
                    fontWeight: "normal",
                  }}
                >
                  <strong>DR. SIVAKUMAR VEERABATHIRAN</strong>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#141414",
                    fontWeight: "normal",
                  }}
                >
                  <strong>MBBS., MS., (Ortho) FIJR</strong>
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </div>
        <DialogActions>
          {postInvoice ? (
            <div className="flex justify-center">
              <CircularProgress size={24} color="inherit" />
            </div>
          ) : (
            <Button
              variant="gradient"
              color="primary"
              onClick={handleDownload}
              sx={{
                ml: 2,
                color: "#ffff",
                backgroundColor: "#662C90",
                padding: "7px",
                borderRadius: "12px",
                marginRight: "492px",
                "&:hover": {
                  backgroundColor: "#5a257e",
                },
              }}
            >
              Download Invoice
            </Button>
          )}
          <Button
            onClick={() => setOpenPreviewModal(false)}
            color="primary"
            style={{ color: "#662C90" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    <ToastContainer position="top-right" />
    </DashboardLayout>
  );
}

export default Invoice;
