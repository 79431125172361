import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Grid, Card, Box } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { Icon } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import { LifeLine } from "react-loading-indicators";
import { ToastContainer, toast } from "react-toastify";
import { BASE_API_URL } from "api/Api";
import Login from "layouts/authentication/sign-in";

const Dischargesummary = () => {
  const navigate = useNavigate();
  const [nextId, setNextId] = useState(14);
  const [Summary, setSummary] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [summaryIdToDelete, setSummaryIdToDelete] = useState(null);

  const handleOpenDeleteDialog = (patientId) => {
    setSummaryIdToDelete(patientId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSummaryIdToDelete(null);
  };

  const confirmDeleteSummary = async () => {
    if (summaryIdToDelete) {
      await handleDelete(summaryIdToDelete);
      handleCloseDeleteDialog();
    }
  };
  const sessionId = useSelector((state) => state.session.sessionId);

  const handleSearch = (event) => setSearchTerm(event.target.value);
  const filteredSummary = Summary.filter((summary) => {
    const patientName = summary.name || "";
    return patientName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    const fetchSummary = async () => {
      const apiUrl = BASE_API_URL;
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "Documents",
        query: "documents_cstm.type_c= 'discharge'",
      });
      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
        setIsLoading(true);
        const response = await axios.get(fullUrl);

        const { description, name } = response.data;
        if (description === "The session ID is invalid" && name === "Invalid Session ID") {
          navigate("/login");
        }

        if (response.data && response.data.entry_list) {
        } else {
        }

        const getSummaryData = () => {
          if (response.data && response.data.entry_list) {
            return response.data.entry_list.map((entry) => {
              const data = entry.name_value_list;

              return {
                id: entry.id,
                uuid_c: data.uuid_c?.value || "",
                invoice_num_c: data.invoice_num_c?.value || "",
                admissiondate_c: data.admissiondate_c?.value || "",
                dates_c: data.dates_c?.value || "",
                description: data.description?.value || "",
                total_c: data.total_c?.value || "",
              };
            });
          }
          return [];
        };

        const summaryData = getSummaryData();

        setSummary(summaryData);

        const maxId = summaryData.reduce((max, summary) => Math.max(max, summary.id), 0);
        setNextId(maxId + 1);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    if (sessionId) {
      fetchSummary();
    }
  }, [sessionId]);

  const handleDelete = async (patientId) => {
    const apiUrl = BASE_API_URL;
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";

    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "Documents",
      name_value_list: {
        id: patientId,
        deleted: { name: "deleted", value: "1" },
      },
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

    try {
      const response = await axios.post(fullUrl);

      if (response.status === 200 && response.data.id) {
        toast.success("Summary deleted successfully!");
        setSummary(Summary.filter((summary) => summary.id !== patientId));
      } else {
        toast.error("Summary delete failed.");
      }
    } catch (error) {
      if (error.response) {
        toast.error("An error occurred during service delete.");
      } else if (error.request) {
        toast.error("No response received from server.");
      } else {
        toast.error("Error setting up request.");
      }
    }
  };

  const handleAddPatientOpen = () => navigate("/dischargesummary/addsummary");
  const handleEditPatientOpen = (id) => navigate(`/dischargesummary/editsummary/${id}`);

  const columns = [
    {
      name: <span style={{ fontWeight: "bold" }}>S.No</span>,
      selector: (row, index) => index + 1,
      sortable: true,
      width: "140px",
      center: true,
      style: { textAlign: "center" },
    },
    {
      name: <span style={{ fontWeight: "bold" }}>ID</span>,
      selector: (row) => (
        <MDTypography
          component="a"
          href="#"
          sx={{
            color: "#3f51b5",
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "13px",
            fontWeight: "bold",
          }}
        >
          {row.uuid_c}
        </MDTypography>
      ),
      sortable: true,
      width: "150px",
      style: { textAlign: "center" },
    },
    {
      name: <span style={{ fontWeight: "bold" }}>Patient Name</span>,
      selector: (row) => row.invoice_num_c,
      sortable: true,
      width: "150px",
      style: { textAlign: "center" },
    },
    {
      name: <span style={{ fontWeight: "bold" }}>Admission Date</span>,
      selector: (row) => row.admissiondate_c,
      sortable: true,
      width: "150px",
      style: { textAlign: "center" },
    },
    {
      name: <span style={{ fontWeight: "bold" }}>Discharge Date</span>,
      selector: (row) => row.dates_c,
      sortable: true,
      width: "150px",
      style: { textAlign: "center" },
    },
    {
      name: <span style={{ fontWeight: "bold" }}>Phone</span>,
      selector: (row) => row.total_c,
      sortable: true,
      width: "120px",
      style: { textAlign: "center" },
    },
    {
      name: <span style={{ fontWeight: "bold" }}>Download</span>,
      cell: (row) => (
        <a
          href={row.description}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#2F855A", fontSize: "18px", display: "flex", justifyContent: "center" }}
        >
          <Icon fontSize="small">get_app</Icon>
        </a>
      ),
      width: "100px",
      center: true,
    },
    {
      name: <span style={{ fontWeight: "bold" }}>Actions</span>,
      cell: (row) => (
        <>
          <IconButton onClick={() => row.id} sx={{ color: "#3f51b5" }}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteDialog(row.id)} sx={{ color: "red" }}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
      width: "120px",
      center: true,
    },
  ];
  if (!sessionId) {
    return <Login />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={3}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="customGradient"
                borderRadius="lg"
                coloredShadow="#EA257C"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  Discharge Summary
                </MDTypography>
                <MDBox display="flex" gap={2}>
                  <MDInput
                    label="Search"
                    value={searchTerm}
                    onChange={handleSearch}
                    InputProps={{
                      sx: { color: "#ffffff" },
                    }}
                    sx={{
                      "& .MuiInputLabel-root": { color: "#ffffff" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#ffffff" },
                        "&:hover fieldset": { borderColor: "#ffffff" },
                      },
                    }}
                  />
                  <Button
                    variant="gradient"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddPatientOpen}
                    sx={{
                      backgroundColor: "#ffffff",
                      color: "secondary",
                      "&:hover": { backgroundColor: "#ffffff" },
                    }}
                  >
                    Discharge Summary
                  </Button>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {isLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className="h-80 bg-opacity-30 bg-white backdrop-blur-lg rounded-lg"
                  >
                    <LifeLine color="#9831cc" size="large" text="" textColor="" />
                  </Box>
                ) : (
                  <DataTable
                    columns={columns}
                    data={filteredSummary}
                    highlightOnHover
                    pagination
                    customStyles={{
                      rows: { style: { minHeight: "50px" } },
                      cells: { style: { fontSize: "13px", textAlign: "center" } },
                    }}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this summary? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirmDeleteSummary}
            sx={{
              color: "red",
              "&:hover": {
                color: "red",
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer position="top-right" autoClose={3000} />
    </DashboardLayout>
  );
};

export default Dischargesummary;
