import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import MDInput from "components/MDInput";
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from '@mui/material';
import { BASE_API_URL } from "api/Api";
import Login from "layouts/authentication/sign-in";

function PatientsRegistration() {
  const sessionId = useSelector((state) => state.session.sessionId);
  const [loading, setLoading] = useState(false);
  const [patientType, setPatientType] = useState('');
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [age, setAge] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [selectedGender, setSelectedGender] = useState(''); 

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const ageDiff = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiff);
    const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    setAge(calculatedAge);
  };  

  useEffect(() => {
    const fetchDoctors = async () => {
      
      const apiUrl = BASE_API_URL;
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "dr_Doctor",
        select_fields: [
          "doctorname_c",
          "specialty_c",
          "degree_c",
          "type_c",
          "availabledays_c",
          "timing_c",
        ],
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;
      
      try {
        const response = await axios.get(fullUrl);
        const { description, name } = response.data;
                if (description === "The session ID is invalid" && name === "Invalid Session ID") {
                  navigate('/login');
                }
        if (response.status === 200 && response.data && response.data.entry_list) {
          const getDoctorData = () => {
            return response.data.entry_list.map((entry) => {
              const data = entry.name_value_list;
              return {
                id: entry.id,
                name: data.doctorname_c.value,
                specialty: data.specialty_c.value,
                degree: data.degree_c.value,
                type: data.type_c.value,
                availableDays: data.availabledays_c.value.split(","),
                timing: data.timing_c.value,
              };
            });
          };

          const doctorData = getDoctorData();
          setDoctors(doctorData);
        } 
    } catch (error) {
        toast.error("Error fetching data:");
      }
    };

    if (sessionId) {
      fetchDoctors();
    }
  }, [sessionId]);

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const genderOptions = ['Male', 'Female', 'Other'];

  const handleDoctorChange = (event) => {
    const { value } = event.target;
    setSelectedDoctors(typeof value === 'string' ? value.split(',') : value);
  };

  const navigate = useNavigate();

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const apiUrl = BASE_API_URL;
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";
    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "Contacts",
      name_value_list: [
        { name: "pateintname_c", value: name },
        { name: "email1", value: email },
        { name: "birthdate", value: dob },
        { name: "patient_age_c", value: age },
        { name: "patient_gender_c", value: selectedGender },
        { name: "phone_mobile", value: phone },
        { name: "patienttype_c", value: patientType },
        { name: "city_c", value: city },
        { name: "state_c", value: country },
        { name: "doc_c", value: selectedDoctors.join(', ') },
        { name: "servicesnames_c", value: "" },
      ]
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

    try {
      const response = await axios.post(fullUrl);
      if (response.status === 200 && response.data.id) {
        if (patientType === 'In Patient') {
          navigate('/inpatients');
        } else {
          navigate('/outpatients');
        }
        toast.success("Patient registered successfully");
      } else {
        toast.error("Register failed");
      }
    } catch (error) { 
        toast.error("Error setting up request");
    }
    finally {
      setLoading(false);
    }
  };

  if (!sessionId) {
    return (
      <Login/>
    );
  }

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <form onSubmit={handleFormSubmit}>
  <div className="flex justify-center mt-8">
    <div className="w-2/4">
      <div className="flex flex-col justify-center items-center gap-4">
        <div className="w-3/4 pr-1 mb-4">
          <MDInput 
            id="name" 
            name="name" 
            label="Name" 
            fullWidth 
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="w-3/4 pr-1 mb-4">
          <label htmlFor="dob" className="block text-sm font-medium text-gray-500 mb-2">Date of Birth</label>
          <input
            type="date"
            id="dob"
            value={dob}
            onChange={(e) => {
              const dobValue = e.target.value;
              setDob(dobValue);
              calculateAge(dobValue);
            }}
            className="w-full p-3 border border-gray-300 rounded-lg text-sm"
            required
          />
        </div>
        <div className="w-3/4 pr-1 mb-4">
          <MDInput 
            id="age" 
            name="age" 
            label="Age" 
            fullWidth 
            value={age}
            readOnly
          />
        </div>
        <div className="w-3/4 pr-1 mb-4 text-gray-500">
          <label className="block text-sm font-medium text-gray-500 mb-2">Gender <span className="text-red-500">*</span></label>
          <Select
            value={selectedGender}
            onChange={handleGenderChange}
            displayEmpty
            required
            fullWidth
            className='p-3'
            renderValue={(selected) => (selected ? selected : 'Select Gender')}
          >
            {genderOptions.map((gender) => (
              <MenuItem key={gender} value={gender}>
                {gender}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="w-3/4 pr-1 mb-4">
          <MDInput 
            id="phone" 
            name="phone" 
            label="Phone No" 
            fullWidth
            required 
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
      </div>
    </div>
    <div className="w-2/4">
      <div className="flex flex-col justify-center items-center gap-4">
        <div className="w-3/4 pr-1 mb-4">
          <MDInput 
            id="email" 
            name="email" 
            label="Email Id" 
            fullWidth 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="w-3/4 pr-1 mb-4">
          <MDInput 
            id="city" 
            name="city" 
            label="City" 
            fullWidth 
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <div className="w-3/4 pr-1 mb-4">
          <MDInput 
            id="country" 
            name="country" 
            label="State" 
            fullWidth 
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </div>
        <div className="w-3/4 pr-1 mb-4 text-gray-500">
          <label className="block text-sm font-medium text-gray-500 mb-2">Patient Type <span className="text-red-500">*</span></label>
          <Select
            value={patientType}
            onChange={(e) => setPatientType(e.target.value)}
            displayEmpty
            required
            fullWidth
            className='p-3'
            renderValue={(selected) => (selected ? selected : 'Add Patient Type')}
          >
            <MenuItem value="Out Patient">Out Patient</MenuItem>
            <MenuItem value="In Patient">In Patient</MenuItem>
          </Select>
        </div>


        <div className="w-3/4 pr-1 mb-4">
          <label htmlFor="multiSelect" className="block text-sm font-medium text-gray-500 mb-2">
            Select Doctor
          </label>
          <Select
            displayEmpty
            multiple
            value={selectedDoctors}
            onChange={handleDoctorChange}
            renderValue={(selected) => selected.length === 0 ? 'Select Doctor' : selected.join(', ')}
            fullWidth
            className="p-3"
            MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
          >
            {doctors.map((doctor) => (
              <MenuItem key={doctor.id} value={doctor.name}>
                <Checkbox checked={selectedDoctors.indexOf(doctor.name) > -1} />
                {doctor.name} - {doctor.specialty}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </div>
  </div>

  <div className="mt-6 text-center">
  {loading ? (
                  <div className="flex justify-center">
                    <CircularProgress size={24} color="inherit" />
                  </div>
                ) : (
    <MDButton type="submit" variant="gradient" color="info">
      Add Patient
    </MDButton>
                )}
  </div>
  <ToastContainer position="top-right" />
</form>

      </DashboardLayout>
    </div>
  )
}

export default PatientsRegistration;
