import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { BASE_API_URL } from "api/Api";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { CircularProgress } from '@mui/material';
import Skeleton from "@mui/material/Skeleton"; 
import Login from "layouts/authentication/sign-in";

const EditInPatient = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [inPatient, setInPatient] = useState(null);
    const [doctors, setDoctors] = useState([]);
    const [availableDoctors, setAvailableDoctors] = useState([]);
    const [availableServices, setAvailableServices] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [inPatients, setInPatients] = useState([]);
    const sessionId = useSelector((state) => state.session.sessionId);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchInPatients = async () => {
            const apiUrl = BASE_API_URL;
            const method = "get_entry_list";
            const input_type = "JSON";
            const response_type = "JSON";
            const rest_data = JSON.stringify({
                session: sessionId,
                module_name: "Contacts",
            });

            const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

            try {
                const response = await axios.get(fullUrl);

                const getPatientsData = () => {
                    if (response.data && response.data.entry_list) {
                        const { description, name } = response.data;
                        if (description === "The session ID is invalid" && name === "Invalid Session ID") {
                          navigate('/login');
                        }
                        return response.data.entry_list.map(entry => {
                            const data = entry.name_value_list;
                            return {
                                id: entry.id,
                                uhid: data.record_number.value,
                                name: data.pateintname_c.value,
                                gender: data.patient_gender_c.value,
                                dob: data.birthdate.value,
                                patientType: data.patienttype_c.value,
                                age: data.patient_age_c.value,
                                email: data.email1.value,
                                phone: data.phone_mobile.value, 
                                city: data.city_c.value,
                                state: data.state_c.value,
                                country: data.patient_country_name_c.value,
                                admissionDate: data.admissiondate_c.value,
                                dischargedDate: data.dischargeddate_c.value,
                                services: data.servicesnames_c.value.split(',')
                                    .map(item => item.trim())
                                    .filter(item => item !== ''),
                                serviceCost: data.servicecost_c.value,
                                dateEntered: data.date_entered.value,
                                fees: data.totalcost_c.value,
                                doctors: data.doc_c.value
                                    .split(',')
                                    .map(item => item.trim())
                                    .filter(item => item !== ''),
                            };
                        });
                    }
                    return [];
                };

                const inPatientsData = getPatientsData();
                setInPatients(inPatientsData); 
            } catch (error) {
                toast.error("Error fetching data");
            }
            finally {
                setLoading(false);
            }
        };

        const fetchServices = async () => {
            const apiUrl = "https://veerahospital.com/billing-software/service/v4_1/rest.php";
            const method = "get_entry_list";
            const input_type = "JSON";
            const response_type = "JSON";
            const rest_data = JSON.stringify({
                session: sessionId,
                module_name: "SVC_Services",
                select_fields: ["service_name_c", "price_c"],
            });

            const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

            try {
                const response = await axios.get(fullUrl);

                if (response.status === 200 && response.data ) {
                  const { description, name } = response.data;
                  if (description === "The session ID is invalid" && name === "Invalid Session ID") {
                    navigate('/login');
                  }

                    const getServiceData = () => {
                        return response.data.entry_list.map((entry) => {
                            const data = entry.name_value_list;
                            return {
                                id: entry.id,
                                name: data.service_name_c.value,
                                amount: data.price_c.value,
                            };
                        }).filter(service => service.name);
                    };

                    const serviceData = getServiceData();
                    setAvailableServices(serviceData);
                } else {
                    toast.error("Unexpected API response");
                }
            } catch (error) {
                toast.error("Error fetching services");
            }
        };

        const fetchDoctors = async () => {
            const apiUrl = BASE_API_URL;
            const method = "get_entry_list";
            const input_type = "JSON";
            const response_type = "JSON";
            const rest_data = JSON.stringify({
                session: sessionId,
                module_name: "dr_Doctor",
                select_fields: [
                    "doctorname_c",
                    "specialty_c",
                    "degree_c",
                    "type_c",
                    "availabledays_c",
                    "timing_c",
                ],
            });

            const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;
            
            try {
                const response = await axios.get(fullUrl);
                if (response.status === 200 && response.data && response.data.entry_list) {
                    const getDoctorData = () => {
                        return response.data.entry_list.map((entry) => {
                            const data = entry.name_value_list;
                            return {
                                id: entry.id,
                                name: data.doctorname_c.value,
                                specialty: data.specialty_c.value,
                                degree: data.degree_c.value,
                                type: data.type_c.value,
                                availableDays: data.availabledays_c.value.split(","),
                                timing: data.timing_c.value,
                            };
                        });
                    };

                    const doctorData = getDoctorData();
                    setDoctors(doctorData);
                }
            } catch (error) {
                toast.error("Error fetching data:", error);
            }
        };

        if (sessionId) {
            fetchInPatients();
            fetchServices();
            fetchDoctors();
        }
    }, [sessionId]);

    const handleDoctorChange = (event) => {
        const { value } = event.target;
        setSelectedDoctor(typeof value === 'string' ? value.split(',') : value);
    };

    const handleServiceChange = (event) => {
        const { value } = event.target;
        setSelectedServices(typeof value === 'string' ? value.split(',') : value);
    };

    useEffect(() => {
        setAvailableDoctors(doctors || []);

        const patient = inPatients.find((patient) => patient.id === id);
        if (patient) {
            setInPatient(patient);
            setSelectedDoctor(patient.doctors || []); 
            setSelectedServices(patient.services || []);
        }
    }, [id, inPatients, doctors]);

    const [updatingPatient, setUpdatingPatient] = useState(false);
    
    const handleSaveEditedInPatient = async (event) => {
        event.preventDefault();
        setUpdatingPatient(true);
        const selectedServiceObjects = availableServices.filter(service =>
            selectedServices.includes(service.name)
        );

        const totalServiceCost = selectedServiceObjects.reduce((total, service) => total + parseFloat(service.amount), 0);

        const updatedInPatient = {
            id: inPatient.id,
            assignedDoctor: selectedDoctor,
            services: selectedServices,
            // uhid: inPatient.uhid,
            name: inPatient.name,
            gender: inPatient.gender,
            dob: inPatient.dob,
            age: inPatient.age,
            phone: inPatient.phone,
            city: inPatient.city,
            state: inPatient.country,
            email: inPatient.email, 
            fees: inPatient.fees,
            serviceCost: totalServiceCost, 
            admissionDate: inPatient.admissionDate,
            dischargedDate: inPatient.dischargedDate,
        };

        const apiUrl = `${BASE_API_URL}`;
        const method = "set_entry";
        const input_type = "JSON";
        const response_type = "JSON";

        const rest_data = JSON.stringify({
            session: sessionId,
            module_name: "Contacts",
            name_value_list: {
                id: updatedInPatient.id,
                // uhid_c: updatedInPatient.uhid,
                pateintname_c: updatedInPatient.name,
                patient_gender_c: updatedInPatient.gender,
                patient_age_c: updatedInPatient.age,
                phone_mobile: updatedInPatient.phone, 
                birthdate: updatedInPatient.dob, 
                city_c: updatedInPatient.city,
                state_c: updatedInPatient.state,
                servicesnames_c: updatedInPatient.services.join(', '),
                servicecost_c: updatedInPatient.serviceCost, 
                totalcost_c: updatedInPatient.fees,
                email1: updatedInPatient.email,
                doc_c: updatedInPatient.assignedDoctor.join(', '),
                admissiondate_c: updatedInPatient.admissionDate,
                dischargeddate_c: updatedInPatient.dischargedDate,
            },
        });

        const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

        try {
            const response = await axios.post(fullUrl);

            if (response.status === 200 && response.data.id) {
                navigate('/inpatients');
                toast.success("Patient Updated Successfully");
            } else {
                toast.error("Patient Update Failed");
            }
        } catch (error) {
           
                toast.error("Error setting up request");
            
        }
        finally {
            setUpdatingPatient(false);
          }
    };
    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const ageDiff = Date.now() - birthDate.getTime();
        const ageDate = new Date(ageDiff);
        const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
        return calculatedAge; 
    };

    if (!sessionId) {
      return (
        <Login/>
      );
    }

    return (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox py={4} mx={5} px={3}>
            <MDTypography variant="h5" mb={2} gutterBottom fontWeight="bold" align="center">
              {loading ? <Skeleton width={200} /> : "Edit In Patients"}
            </MDTypography>
    
            {loading ? (
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                  {[...Array(6)].map((_, index) => (
                    <Skeleton key={index} variant="rectangular" height={40} sx={{ mt: 2 }} />
                  ))}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {[...Array(6)].map((_, index) => (
                    <Skeleton key={index} variant="rectangular" height={40} sx={{ mt: 2 }} />
                  ))}
                </Grid>
              </Grid>
            ) : inPatient ? (
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    label="Name"
                    value={inPatient.name || ''}
                    onChange={(e) => setInPatient({ ...inPatient, name: e.target.value })}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <MDInput
                    label="Gender"
                    value={inPatient.gender || ''}
                    onChange={(e) => setInPatient({ ...inPatient, gender: e.target.value })}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <MDInput
                    label="Date of Birth"
                    type="date"
                    value={inPatient.dob || ''}
                    onChange={(e) => {
                      const dobValue = e.target.value;
                      setInPatient(prev => ({
                        ...prev,
                        dob: dobValue,
                        age: calculateAge(dobValue)
                      }));
                    }}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <MDInput
                    label="Age"
                    type="number"
                    value={inPatient.age || ''}
                    onChange={(e) => setInPatient({ ...inPatient, age: e.target.value })}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <MDInput
                    label="Phone Number"
                    value={inPatient.phone || ''}
                    onChange={(e) => setInPatient({ ...inPatient, phone: e.target.value })}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <MDInput
                    label="City"
                    value={inPatient.city || ''}
                    onChange={(e) => setInPatient({ ...inPatient, city: e.target.value })}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <MDInput
                    label="Email"
                    value={inPatient.email || ''}
                    onChange={(e) => setInPatient({ ...inPatient, email: e.target.value })}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label htmlFor="doctor-select" className="text-sm">Doctor</label>
                  <Select
                    id="doctor-select"
                    multiple
                    value={selectedDoctor}
                    onChange={handleDoctorChange}
                    displayEmpty
                    fullWidth
                    className="p-3"
                    renderValue={(selected) => selected.length === 0 ? 'Add Doctor' : selected.join(', ')}
                    sx={{ mt: 1 }}
                  >
                    {availableDoctors.map((doctor) => (
                      <MenuItem key={doctor.id} value={doctor.name}>
                        <Checkbox checked={selectedDoctor.indexOf(doctor.name) > -1} />
                        {doctor.name} - {doctor.specialty}
                      </MenuItem>
                    ))}
                  </Select>
                  <label htmlFor="service-select" className="text-sm" style={{ marginTop: 20 }}>Services</label>
                  <Select
                    id="service-select"
                    multiple
                    value={selectedServices}
                    onChange={handleServiceChange}
                    displayEmpty
                    fullWidth
                    className="p-3"
                    renderValue={(selected) => selected.length === 0 ? 'Add Services' : selected.join(', ')}
                    sx={{ mt: 1 }}
                  >
                    {availableServices.map((service) => (
                      <MenuItem key={service.id} value={service.name}>
                        <Checkbox checked={selectedServices.indexOf(service.name) > -1} />
                        {service.name} - {service.amount}
                      </MenuItem>
                    ))}
                  </Select>
                  <MDInput
                    label="Other Fees"
                    type="number"
                    value={inPatient.fees || ''}
                    onChange={(e) => setInPatient({ ...inPatient, fees: e.target.value })}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <MDInput
                    label="State"
                    value={inPatient.state || ''}
                    onChange={(e) => setInPatient({ ...inPatient, country: e.target.value })}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <MDInput
                    label="Admission Date"
                    type="date"
                    value={inPatient.admissionDate || ''}
                    onChange={(e) => setInPatient({ ...inPatient, admissionDate: e.target.value })}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <MDInput
                    label="Discharged Date"
                    type="date"
                    value={inPatient.dischargedDate || ''}
                    onChange={(e) => setInPatient({ ...inPatient, dischargedDate: e.target.value })}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                </Grid>
              </Grid>
            ) : (
              <MDTypography variant="h5" fontWeight="bold" align="center">
                No In Patients available at the moment
              </MDTypography>
            )}
    
            {!loading && inPatient && (
              <MDBox display="flex" justifyContent="center" mt={4} gap={5}>
                <MDButton variant="contained" color="error" onClick={() => navigate('/inpatients')}>
                  Cancel
                </MDButton>
                {updatingPatient ? (
                  <div className="flex justify-center">
                    <CircularProgress size={24} color="inherit" />
                  </div>
                ) : (
        <MDButton variant="contained" color="success" onClick={handleSaveEditedInPatient}>
                  Save
                </MDButton>
                )}
                
              </MDBox>
            )}
    
            <ToastContainer position="top-right" />
          </MDBox>
        </DashboardLayout>
      );
    }

export default EditInPatient;
