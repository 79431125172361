import React, { useState, useEffect } from "react";
import { BASE_API_URL } from "api/Api";
import axios from "axios";
import { useSelector } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Login from "layouts/authentication/sign-in";

function Dashboard() {
  const navigate = useNavigate();
  const [totalInPatients, setTotalInPatients] = useState(0); // Total In Patients
  const [totalOutPatients, setTotalOutPatients] = useState(0); // Total Out Patients
  const [todayInPatients, setTodayInPatients] = useState(0); // Today's In Patients
  const [todayOutPatients, setTodayOutPatients] = useState(0); // Today's Out Patients

  const sessionId = useSelector((state) => state.session.sessionId);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPatients = async () => {
      const apiUrl = BASE_API_URL;
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "Contacts",
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
        const response = await axios.get(fullUrl);
        const { description, name } = response.data;
        if (description === "The session ID is invalid" && name === "Invalid Session ID") {
          navigate("/login");
        }
        const getPatientsData = () => {
          if (response.data && response.data.entry_list) {
            return response.data.entry_list.map((entry) => {
              const data = entry.name_value_list;
              return {
                id: entry.id,
                patientType: data.patienttype_c?.value, // In/Out Patient type
                createdDate: data.date_entered.value, // Date entered
              };
            });
          }
          return [];
        };

        const PatientsData = getPatientsData();

        // Filter total in-patients and out-patients
        const totalInPatientsCount = PatientsData.filter((patient) =>
          patient.patientType.includes("In Patient")
        ).length;
        const totalOutPatientsCount = PatientsData.filter((patient) =>
          patient.patientType.includes("Out Patient")
        ).length;
        setTotalInPatients(totalInPatientsCount);
        setTotalOutPatients(totalOutPatientsCount);

        // Get today's date in YYYY-MM-DD format
        const today = new Date().toISOString().split("T")[0];

        // Filter today's in-patients and out-patients
        const todayInPatientsCount = PatientsData.filter(
          (patient) =>
            patient.patientType.includes("In Patient") &&
            patient.createdDate.split(" ")[0] === today
        ).length;

        const todayOutPatientsCount = PatientsData.filter(
          (patient) =>
            patient.patientType.includes("Out Patient") &&
            patient.createdDate.split(" ")[0] === today
        ).length;

        setTodayInPatients(todayInPatientsCount);
        setTodayOutPatients(todayOutPatientsCount);
      } catch (error) {
        toast.error("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (sessionId) {
      fetchPatients();
    }
  }, [sessionId]);

  const totalPatients = totalInPatients + totalOutPatients;

  if (!sessionId) {
    return <Login />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={8} px={8}>
        <Grid container spacing={10}>
          {/* Total Patients Card */}
          <Grid item xs={12} sm={6}>
            <MDBox mb={1.5}>
              {loading ? (
                <Skeleton variant="rectangular" height={120} />
              ) : (
                <ComplexStatisticsCard
                  color="dark"
                  icon="group"
                  title="Total Patients"
                  count={totalPatients}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "",
                  }}
                />
              )}
            </MDBox>
          </Grid>

          {/* In Patients Card */}
          <Grid item xs={12} sm={6}>
            <MDBox mb={1.5}>
              {loading ? (
                <Skeleton variant="rectangular" height={120} />
              ) : (
                <ComplexStatisticsCard
                  icon="local_hospital_icon"
                  title="In Patients"
                  count={totalInPatients} // Display total in-patients count
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "",
                  }}
                />
              )}
            </MDBox>
          </Grid>

          {/* Out Patients Card */}
          <Grid item xs={12} sm={6}>
            <MDBox mb={1.5}>
              {loading ? (
                <Skeleton variant="rectangular" height={120} />
              ) : (
                <ComplexStatisticsCard
                  color="success"
                  icon="medical_services_icon"
                  title="Out Patients"
                  count={totalOutPatients} // Display total out-patients count
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "",
                  }}
                />
              )}
            </MDBox>
          </Grid>

          {/* Today Patients Card */}
          <Grid item xs={12} sm={6}>
            <MDBox mb={1.5}>
              {loading ? (
                <Skeleton variant="rectangular" height={120} />
              ) : (
                <ComplexStatisticsCard
                  color="primary"
                  icon="schedule_icon"
                  title="Today Patients"
                  count={todayInPatients + todayOutPatients} // Display total of today's patients
                  percentage={{
                    color1: "success",
                    amount1: todayInPatients, // Today's in-patients count
                    label1: "In Patients",
                    color2: "success",
                    amount2: todayOutPatients, // Today's out-patients count
                    label2: "Out Patients",
                  }}
                />
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <ToastContainer position="top-right" autoClose={3000} />
    </DashboardLayout>
  );
}

export default Dashboard;
