import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const initialInvoiceData = [
  {
    id: 1,
    invoiceNumber: "UHID001",
    city: "Devakottai",
    patientName: "VeeraPrabhu",
    admissionDate: "2024-07-01",
    dischargeDate: "2024-07-05",
    phone: "9826777832",
    patientType: "OP",
    surgeryDate: "2024-07-20",
    age: "23",
    consultancy: "Dr:Sivakumar MBBS",
    Diagnosis: "chronic cholasystitic with cholalithiasis",
    procedure: "Reported Attached",
    present: "c/0 pain upper abdomen for 1 year",
  },
  {
    id: 2,
    invoiceNumber: "UHID002",
    city: "Paramakudi",
    patientName: "VeeraPandi",
    admissionDate: "2024-07-05",
    dischargeDate: "2024-07-10",
    phone: "9876543210",
    patientType: "IP",
    surgeryDate: "2024-07-20",
    age: "23",
    consultancy: "Dr:Sivakumar MBBS",
    Diagnosis: "chronic cholasystitic with cholalithiasis",
    procedure: "Reported Attached",
    present: "c/0 pain upper abdomen for 1 year",
  },
  {
    id: 3,
    invoiceNumber: "UHID003",
    city: "Sivakasi",
    patientName: "Gopal",
    admissionDate: "2024-07-10",
    dischargeDate: "2024-07-15",
    phone: "9551234567",
    patientType: "OP",
    surgeryDate: "2024-07-20",
    age: "23",
    consultancy: "Dr:Sivakumar MBBS",
    Diagnosis: "chronic cholasystitic with cholalithiasis",
    procedure: "Reported Attached",
    present: "c/0 pain upper abdomen for 1 year",
  },
  {
    id: 4,
    invoiceNumber: "UHID004",
    city: "Rsmangalam",
    patientName: "Karthik",
    admissionDate: "2024-07-12",
    dischargeDate: "2024-07-20",
    phone: "8659876543",
    patientType: "IP",
    surgeryDate: "2024-07-20",
    age: "23",
    consultancy: "Dr:Sivakumar MBBS",
    Diagnosis: "chronic cholasystitic with cholalithiasis",
    procedure: "Reported Attached",
    present: "c/0 pain upper abdomen for 1 year",
  },
  {
    id: 5,
    invoiceNumber: "UHID005",
    city: "K.K.Nagar",
    patientName: "Vishnu",
    admissionDate: "2024-07-15",
    dischargeDate: "2024-07-22",
    phone: "5556781234",
    patientType: "OP",
    surgeryDate: "2024-07-20",
    age: "23",
    consultancy: "Dr:Sivakumar MBBS",
    Diagnosis: "chronic cholasystitic with cholalithiasis",
    procedure: "Reported Attached",
    present: "c/0 pain upper abdomen for 1 year",
  },
];

const EditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    const invoiceToEdit = initialInvoiceData.find((inv) => inv.id === parseInt(id));
    if (invoiceToEdit) {
      setInvoice(invoiceToEdit);
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    navigate("/dischargesummary");
  };

  const handleSave = () => {
    if (invoice) {
      console.log("Updated Invoice:", invoice);
    }

    navigate("/dischargesummary");
  };

  if (!invoice) {
    return <div>Loading...</div>;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Container maxWidth="lg">
        <Box
          sx={{
            width: "90%",
            maxWidth: "900px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-end",
            padding: "50px",
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            margin: "20px auto",
            marginLeft: "auto",
          }}
        >
          <form style={{ width: "100%", display: "flex", flexDirection: "row", gap: "20px" }}>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "15px" }}>
              <TextField
                name="id"
                label="ID"
                value={invoice.id}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="dense"
                InputProps={{ readOnly: true }}
              />
              <TextField
                name="patientName"
                label="Patient Name"
                value={invoice.patientName}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="dense"
              />
              <TextField
                name="city"
                label="City"
                value={invoice.city}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="dense"
              />
              <TextField
                name="phone"
                label="Phone Number"
                value={invoice.phone}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="dense"
              />
              <TextField
                name="admissionDate"
                label="Admission Date"
                value={invoice.admissionDate}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="dense"
              />
            </Box>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "15px" }}>
              <TextField
                name="dischargeDate"
                label="Discharge Date"
                value={invoice.dischargeDate}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="dense"
              />
              <FormControl
                fullWidth
                size="small"
                margin="dense"
                sx={{
                  padding: "3px 0",
                  height: "35px",
                }}
              >
                <InputLabel id="patient-type-label">Patient Type</InputLabel>
                <Select
                  labelId="patient-type-label"
                  name="patientType"
                  value={invoice.patientType}
                  onChange={handleInputChange}
                  sx={{
                    height: "35px",
                    padding: "10px 10px",
                    fontSize: "12px",
                  }}
                >
                  <MenuItem value="OP">OP</MenuItem>
                  <MenuItem value="IP">IP</MenuItem>
                </Select>
              </FormControl>

              <TextField
                name="age"
                label="Age"
                value={invoice.age}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="dense"
              />
              <TextField
                name="surgeryDate"
                label="Surgery Date"
                value={invoice.surgeryDate}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="dense"
              />
              <TextField
                name="consultancy"
                label="Consultancy"
                value={invoice.consultancy}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="dense"
              />
            </Box>
          </form>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <Box sx={{ flex: 1 }}>
              <TextField
                name="Diagnosis"
                label="Diagnosis"
                value={invoice.Diagnosis}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="dense"
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextField
                name="procedure"
                label="Procedure"
                value={invoice.procedure}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="dense"
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextField
                name="present"
                label="Presenting Complaint"
                value={invoice.present}
                onChange={handleInputChange}
                fullWidth
                size="small"
                margin="dense"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleCancel}
              sx={{
                color: "#ffff",
                backgroundColor: "red",
                "&:hover": { backgroundColor: "darkred" },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="gradient"
              onClick={handleSave}
              sx={{
                color: "#ffff",
                backgroundColor: "green",
                "&:hover": { backgroundColor: "darkgreen" },
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default EditPage;
