import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import logoImage from "assets/images/HMS.webp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import axios from "axios";
import { BASE_API_URL } from "api/Api";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import Login from "layouts/authentication/sign-in";
import { LifeLine } from "react-loading-indicators";
// import DataTable from 'react-data-table-component';

function Lab() {
  const navigate = useNavigate();
  const [patients, setPatients] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const sessionId = useSelector((state) => state.session.sessionId);
  useEffect(() => {
    const fetchPatients = async () => {
      const apiUrl = BASE_API_URL;
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "Contacts",
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
        const response = await axios.get(fullUrl);

        const { description, name } = response.data;
        if (description === "The session ID is invalid" && name === "Invalid Session ID") {
          navigate("/login");
        }

        const getPatientsData = () => {
          if (response.data && response.data.entry_list) {
            return response.data.entry_list
              .map((entry) => {
                const data = entry.name_value_list;

                const serviceCost = parseFloat(data.servicecost_c.value) || 0;
                const totalCost = parseFloat(data.totalcost_c.value) || 0;

                const fees = serviceCost + totalCost;
                return {
                  hospitality: "",
                  patientId: entry.id,
                  id: data.record_number.value,
                  name: data.pateintname_c.value,
                  gender: data.patient_gender_c.value,
                  patientType: data.patienttype_c.value,
                  age: data.patient_age_c.value,
                  email: data.email1.value,
                  phoneNumber: data.phone_mobile.value,
                  city: data.city_c.value,
                  state: data.state_c.value,
                  country: data.patient_country_name_c.value,
                  admissionDate: data.admissiondate_c.value,
                  dischargedDate: data.dischargeddate_c.value,
                  serviceName: data.servicesnames_c.value,
                  servicecost: data.servicecost_c.value,
                  totalAmount: fees,
                  amount: data.servicecost_c.value,
                  doctor: data.doc_c.value,
                  totalCost: totalCost,
                  dateEntered: data.date_entered.value,
                };
              })
              .filter((patient) => patient.patientType);
          }
          return [];
        };

        const PatientsData = getPatientsData();
        setPatients(PatientsData);
      } catch (error) {
        toast.error("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    const fetchDoctors = async () => {
      const apiUrl = BASE_API_URL;
      const method = "get_entry_list";
      const input_type = "JSON";
      const response_type = "JSON";
      const rest_data = JSON.stringify({
        session: sessionId,
        module_name: "dr_Doctor",
        select_fields: [
          "doctorname_c",
          "specialty_c",
          "degree_c",
          "type_c",
          "availabledays_c",
          "timing_c",
        ],
      });

      const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${rest_data}`;

      try {
        const response = await axios.get(fullUrl);
        const { description, name } = response.data;
        if (description === "The session ID is invalid" && name === "Invalid Session ID") {
          navigate("/login");
        }
        if (response.status === 200 && response.data && response.data.entry_list) {
          const getDoctorData = () => {
            return response.data.entry_list.map((entry) => {
              const data = entry.name_value_list;
              return {
                id: entry.id,
                name: data.doctorname_c.value,
                specialty: data.specialty_c.value,
                degree: data.degree_c.value,
                type: data.type_c.value,
                availableDays: data.availabledays_c.value.split(","),
                timing: data.timing_c.value,
              };
            });
          };

          const doctorData = getDoctorData();
          setDoctors(doctorData);
        }
      } catch (error) {
        toast.error("Error fetching data");
      }
    };

    if (sessionId) {
      fetchPatients();
      fetchDoctors();
    }
  }, [sessionId]);

  const handleDoctorChange = (event) => {
    const { value } = event.target;
    setSelectedDoctors(typeof value === "string" ? value.split(",") : value);
  };

  //   const invoiceRef = useRef();
  const [toDetails, setToDetails] = useState({
    uhid: "",
    patientId: "",
    name: "",
    city: "",
    phone: "",
    age: "",
    gender: "",
    refDoctor: "",
    specimen: "",
    collectedOn: "",
    reportedOn: "",
  });

  const handleIdChange = (event) => {
    const { value } = event.target;
    setToDetails((prevDetails) => ({ ...prevDetails, uhid: value }));

    const matchedData = patients.find((item) => item.id.toString() === value);
    if (matchedData) {
      setToDetails((prevDetails) => ({
        ...prevDetails,
        patientId: matchedData.patientId,
        name: matchedData.name,
        city: matchedData.city,
        phone: matchedData.phoneNumber,
        age: matchedData.age,
        gender: matchedData.gender,
        refDoctor: "",
        specimen: "",
        collectedOn: "",
        reportedOn: "",
      }));
    } else {
      // Clear fields if no match is found
      setToDetails((prevDetails) => ({
        ...prevDetails,
        patientId: "",
        name: "",
        city: "",
        phone: "",
        age: "",
        gender: "",
        refDoctor: "",
        specimen: "",
        collectedOn: "",
        reportedOn: "",
      }));

      // Optionally push a new patient entry
      patients.push({
        id: value,
        name: "",
        city: "",
        phoneNumber: "",
        email: "",
        invoiceDate: new Date().toISOString().split("T")[0],
        totalAmount: 0,
        otherFees: 0,
        status: "Pending",
        uhid: "",
        doctorName: "",
      });
    }
  };

  const handleChange = (field) => (event) => {
    setToDetails((prevDetails) => ({
      ...prevDetails,
      [field]: event.target.value,
    }));
  };

  const [selectedTest, setSelectedTest] = useState({
    name: "",
    result: "",
    referenceValue: "",
    unit: "",
  });
  const [tests, setTests] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [testOptions] = useState([
    { name: "Blood Test", referenceValue: "4.5-5.5", unit: "g/dL" },
    { name: "X-Ray", referenceValue: "N/A", unit: "N/A" },
    { name: "MRI", referenceValue: "N/A", unit: "N/A" },
    { name: "Urine Test", referenceValue: "1.005-1.030", unit: "g/cm³" },
  ]);

  const handleTestChange = (event) => {
    const selectedName = event.target.value;
    const selected = testOptions.find((option) => option.name === selectedName);

    if (selected) {
      setSelectedTest({
        ...selectedTest,
        name: selected.name,
        referenceValue: selected.referenceValue,
        unit: selected.unit,
      });
    } else {
      setSelectedTest({
        name: "",
        result: "",
        referenceValue: "",
        unit: "",
      });
    }
  };

  const handleFieldChange = (field, value) => {
    setSelectedTest((prevTest) => ({
      ...prevTest,
      [field]: value,
    }));
  };

  const handleSave = () => {
    if (!selectedTest.name || !selectedTest.result) {
      alert("Please enter all required fields.");
      return;
    }

    setTests((prevTests) => [...prevTests, selectedTest]);
    resetSelectedTest();
  };

  const handleSaveEdit = () => {
    if (!selectedTest.name || !selectedTest.result) {
      alert("Please enter all required fields.");
      return;
    }

    setTests((prevTests) => {
      const updatedTests = prevTests.map((test) =>
        test.name === selectedTest.name ? selectedTest : test
      );
      return updatedTests;
    });

    closeDialog();
  };

  const handleEditOpen = (index) => {
    const testToEdit = tests[index];
    setSelectedTest(testToEdit);
    setDialogOpen(true);
  };

  const handleDelete = (index) => {
    setTests((prevTests) => prevTests.filter((_, i) => i !== index));
  };

  const closeDialog = () => {
    setDialogOpen(false);
    resetSelectedTest();
  };

  const resetSelectedTest = () => {
    setSelectedTest({
      name: "",
      result: "",
      referenceValue: "",
      unit: "",
    });
  };

  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const handlePreview = () => {
    setOpenPreviewModal(true);
  };

  const handleDownload = async () => {
    const canvas = await html2canvas(document.querySelector("#invoiceCaptured"), { scale: 4 });
    const imgData = canvas.toDataURL("image/png", 1); // Adjust the quality here (0.0 to 1.0)

    // Create a new jsPDF instance with A4 size
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
      compress: true, // Compress the PDF
    });

    // Get image properties
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Calculate dimensions to maintain aspect ratio
    const ratio = Math.min(pdfWidth / imgProps.width, pdfHeight / imgProps.height);
    const imgWidth = imgProps.width * ratio;
    const imgHeight = imgProps.height * ratio;

    // Add image to PDF
    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

    const base64PDF = pdf.output("datauristring").split(",")[1];

    // Define the name of the PDF
    const pdfName = `${toDetails.name}.pdf`;

    // Save the PDF to the user's device
    pdf.save(pdfName);
  };

  if (!sessionId) {
    return <Login />;
  }
  if (loading) {
    return (
      <DashboardLayout>
        <div className="flex justify-center items-center h-80 bg-opacity-30 bg-white backdrop-blur-lg rounded-lg">
          <LifeLine color="#9831cc" size="large" text="" textColor="" />
        </div>
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={14}>
            <Card>
              <Box p={4}>
                <Box textAlign="center" mb={3}>
                  <Typography variant="h4" fontWeight="bold" mb={2}>
                    Lab Report
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={3}>
                  <Box>
                    <Typography variant="body1" component="div">
                      <strong>VeeraHospital</strong>
                      <br />
                      01, Silambani Sannathi Street, inside Arch Devakottai 630 302
                      <br />
                      <strong>Email:</strong> veerahospital@gmail.com
                      <br />
                      <strong>Phone-No:</strong> 98406 29905
                    </Typography>
                  </Box>
                  <Box>
                    <img src={logoImage} alt="Invoice Logo" style={{ width: "150px" }} />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Box
                    border={1}
                    borderColor="grey.400"
                    borderRadius="18px"
                    p={3}
                    width="100%"
                    bgcolor="#ffffff"
                  >
                    <Box textAlign="center" mb={1}>
                      <Typography variant="h5" fontWeight="bold">
                        Patient Details
                      </Typography>
                    </Box>
                    <div className="grid grid-cols-3 gap-1 p-0 mx-3">
                      <TextField
                        label="UHID"
                        name="id"
                        value={toDetails.uhid}
                        onChange={handleIdChange}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <TextField
                        label="Patient Name"
                        name="patientName"
                        value={toDetails.name}
                        onChange={handleChange("name")}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <TextField
                        label="Phone Number"
                        name="phoneNumber"
                        value={toDetails.phone}
                        onChange={handleChange("phone")}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <TextField
                        label="Age"
                        name="age"
                        value={toDetails.age}
                        onChange={handleChange("age")}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <TextField
                        label="Gender"
                        name="gender"
                        value={toDetails.gender}
                        onChange={handleChange("gender")}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <TextField
                        label="Specimen"
                        name="specimen"
                        value={toDetails.specimen}
                        onChange={handleChange("specimen")}
                        fullWidth
                        margin="normal"
                        sx={{
                          borderRadius: "15px",
                          width: "275px",
                          backgroundColor: "#D8F5EE",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "45px",
                          },
                        }}
                      />
                      <div>
                        <label
                          htmlFor="multiSelect"
                          className="block text-sm font-medium text-gray-500 mb-4"
                        >
                          Reference Doctor:
                        </label>
                        <Select
                          displayEmpty
                          value={selectedDoctors}
                          onChange={handleDoctorChange}
                          renderValue={(selected) =>
                            selected.length === 0 ? "Select Doctor" : selected.join(", ")
                          }
                          fullWidth
                          className="p-3"
                          MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                          sx={{
                            borderRadius: "15px",
                            width: "275px",
                            backgroundColor: "#D8F5EE",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "15px",
                              height: "45px",
                            },
                          }}
                        >
                          {doctors.map((doctor) => (
                            <MenuItem key={doctor.id} value={doctor.name}>
                              <Checkbox checked={selectedDoctors.indexOf(doctor.name) > -1} />
                              Dr. {doctor.name} - {doctor.specialty}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div>
                        <label
                          htmlFor="multiSelect"
                          className="block text-sm font-medium text-gray-500 mb-0"
                        >
                          Collected Date:
                        </label>
                        <TextField
                          type="date"
                          name="collectedOn"
                          value={toDetails.collectedOn}
                          onChange={handleChange("collectedOn")}
                          fullWidth
                          margin="normal"
                          sx={{
                            borderRadius: "15px",
                            width: "275px",
                            backgroundColor: "#D8F5EE",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "15px",
                              height: "45px",
                            },
                          }}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="multiSelect"
                          className="block text-sm font-medium text-gray-500 mb-0"
                        >
                          Reported Date:
                        </label>
                        <TextField
                          type="date"
                          name="reportedOn"
                          value={toDetails.reportedOn}
                          onChange={handleChange("reportedOn")}
                          fullWidth
                          margin="normal"
                          sx={{
                            borderRadius: "15px",
                            width: "275px",
                            backgroundColor: "#D8F5EE",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "15px",
                              height: "45px",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                </Box>

                {/* Add test */}
                <Box>
                  <Box
                    border={1}
                    borderColor="grey.400"
                    borderRadius="18px"
                    p={3}
                    width="100%"
                    bgcolor="#ffffff"
                  >
                    <Box p={2}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            select
                            label="Select Test"
                            fullWidth
                            value={selectedTest.name}
                            onChange={handleTestChange}
                            InputProps={{
                              style: { padding: "12px" },
                            }}
                          >
                            {testOptions.map((option) => (
                              <MenuItem key={option.name} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Test Name"
                            value={selectedTest.name}
                            fullWidth
                            onChange={(e) => handleFieldChange("name", e.target.value)}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Result"
                            value={selectedTest.result}
                            required
                            onChange={(e) => handleFieldChange("result", e.target.value)}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Reference Value"
                            value={selectedTest.referenceValue}
                            disabled={!selectedTest.name} // Disable if no test is selected
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Unit"
                            value={selectedTest.unit}
                            disabled={!selectedTest.name} // Disable if no test is selected
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <MDButton onClick={handleSave} variant="contained" color="primary">
                            Save
                          </MDButton>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box mt={4}>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(6, 1fr)"
                        gap={1.5}
                        bgcolor="#662C90"
                        borderRadius="8px"
                        p={1}
                        mb={2}
                      >
                        <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                          S.No
                        </Typography>
                        <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                          Test Name
                        </Typography>
                        <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                          Result
                        </Typography>
                        <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                          Reference Value
                        </Typography>
                        <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                          Unit
                        </Typography>
                        <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                          Actions
                        </Typography>
                      </Box>
                      {tests.map((test, index) => (
                        <Box
                          key={index}
                          display="grid"
                          gridTemplateColumns="repeat(6, 1fr)"
                          gap={2}
                          p={1}
                          borderBottom={1}
                          borderColor="grey.300"
                        >
                          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                            {index + 1}
                          </Typography>
                          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                            {test.name}
                          </Typography>
                          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                            {test.result}
                          </Typography>
                          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                            {test.referenceValue}
                          </Typography>
                          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                            {test.unit}
                          </Typography>
                          <Box>
                            <IconButton onClick={() => handleEditOpen(index)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => handleDelete(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    {/* Dialog for Editing Tests */}
                    <Dialog open={dialogOpen} onClose={closeDialog}>
                      <DialogTitle>Edit Test</DialogTitle>
                      <DialogContent>
                        <TextField
                          label="Test Name"
                          value={selectedTest.name}
                          onChange={(e) => handleFieldChange("name", e.target.value)}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Result"
                          value={selectedTest.result}
                          onChange={(e) => handleFieldChange("result", e.target.value)}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          label="Reference Value"
                          value={selectedTest.referenceValue}
                          onChange={(e) => handleFieldChange("referenceValue", e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled
                        />
                        <TextField
                          label="Unit"
                          value={selectedTest.unit}
                          onChange={(e) => handleFieldChange("unit", e.target.value)}
                          fullWidth
                          margin="normal"
                          disabled
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={closeDialog}>Cancel</Button>
                        <Button onClick={handleSaveEdit}>Save</Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </Box>

                <Box display="flex" justifyContent="spacebetween" mt={4}>
                  <Button
                    variant="gradient"
                    color="primary"
                    onClick={handlePreview}
                    sx={{
                      color: "#FFFFFF",
                      borderRadius: "18px",
                      backgroundColor: "#662C90",
                      padding: "9px",
                      marginRight: "492px",
                      "&:hover": {
                        backgroundColor: "#5a257e",
                      },
                    }}
                  >
                    Preview Invoice
                  </Button>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Preview */}
      <Dialog
        open={openPreviewModal}
        onClose={() => setOpenPreviewModal(false)}
        fullWidth
        maxWidth="md"
      >
        <div id="invoiceCaptured">
          <DialogTitle
            sx={{
              textAlign: "left", // Align everything to the left
              padding: "10px 25px",
              backgroundColor: "#f5f5f5",
              color: "#662C90",
              borderBottom: "1px solid #e0e0e0",
              fontSize: "1.5rem",
              fontWeight: "bold",
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start", // Aligns logo and details close to the left
            }}
          >
            <Box
              component="img"
              sx={{
                height: 50,
                marginLeft: 2,
                marginRight: 4, // Adds space between the logo and the text
              }}
              alt="Veera Logo"
              src={logoImage}
            />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#662C90", fontSize: "1.3rem" }}
              >
                VEERA HOSPITAL
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", fontSize: "0.85rem", color: "#141414" }}
              >
                Orthopedic Hospital
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", color: "#141414", fontSize: "0.85rem" }}
              >
                01, Silambani Sannathi Street, inside Arch, Devakottai 630 302
              </Typography>
              <Typography variant="body2" sx={{ color: "#141414", fontSize: "0.85rem" }}>
                <strong>Email:</strong> veerahospital@gmail.com | <strong>Phone-No:</strong> 98406
                29905 |<strong>Hours:</strong> Open 24 hours
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className="flex justify-center mt-2.5 mb-1">
              <Typography variant="h5" fontWeight="bold">
                Lab Report
              </Typography>
            </Box>
            <Box pl={3} pr={3}>
              <Typography variant="h5" ml={4} fontWeight="bold">
                <strong>Patient Name :</strong> {toDetails.name}
              </Typography>
              <Grid container spacing={0.5} mb={1} mt={0.5}>
                {/* Row 1 - ID */}
                <Grid item xs={3} textAlign="right">
                  <Typography variant="h6">
                    <strong>ID :</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign="left">
                  <Typography variant="body2">{toDetails.uhid}</Typography>
                </Grid>

                {/* Row 2 - Age/Gender */}
                <Grid item xs={3} textAlign="right">
                  <Typography variant="h6">
                    <strong>Age/Gender :</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign="left">
                  <Typography variant="body2">
                    {toDetails.age}/{toDetails.gender}
                  </Typography>
                </Grid>

                {/* Row 3 - Phone-No */}
                <Grid item xs={3} textAlign="right">
                  <Typography variant="h6">
                    <strong>Phone-No :</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign="left">
                  <Typography variant="body2">{toDetails.phone}</Typography>
                </Grid>

                {/* Row 4 - City */}
                <Grid item xs={3} textAlign="right">
                  <Typography variant="h6">
                    <strong>City :</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign="left">
                  <Typography variant="body2">{toDetails.city}</Typography>
                </Grid>

                {/* Row 5 - Ref Dr */}
                <Grid item xs={3} textAlign="right">
                  <Typography variant="h6">
                    <strong>Ref Dr :</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign="left">
                  <Typography variant="body2">Dr. {selectedDoctors}</Typography>
                </Grid>

                {/* Row 6 - Specimen */}
                <Grid item xs={3} textAlign="right">
                  <Typography variant="h6">
                    <strong>Specimen :</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign="left">
                  <Typography variant="body2">{toDetails.specimen}</Typography>
                </Grid>

                {/* Row 7 - Collected On */}
                <Grid item xs={3} textAlign="right">
                  <Typography variant="h6">
                    <strong>Collected On :</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign="left">
                  <Typography variant="body2">{toDetails.collectedOn}</Typography>
                </Grid>

                {/* Row 8 - Reported On */}
                <Grid item xs={3} textAlign="right">
                  <Typography variant="h6">
                    <strong>Reported On :</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign="left">
                  <Typography variant="body2">{toDetails.reportedOn}</Typography>
                </Grid>
              </Grid>

              <Box mt={2}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(5, 1fr)"
                  gap={1.5}
                  bgcolor="#662C90"
                  borderRadius="8px"
                  p={1}
                  mb={0.5}
                >
                  <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                    S.NO
                  </Typography>
                  <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                    Test Name
                  </Typography>
                  <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                    Result
                  </Typography>
                  <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                    Reference Value
                  </Typography>
                  <Typography variant="h6" fontWeight="bold" style={{ color: "#ffffff" }}>
                    Unit Type
                  </Typography>
                </Box>
                {tests.map((test, index) => (
                  <Box
                    key={index}
                    display="grid"
                    gridTemplateColumns="repeat(5, 1fr)"
                    gap={1.5}
                    p={0.5}
                    borderBottom={1}
                    borderColor="grey.300"
                  >
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                      {index + 1}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                      {test.name}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                      {test.result}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                      {test.referenceValue}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                      {test.unit}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </DialogContent>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "50px",
                marginRight: "30px",
              }}
            >
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#141414", // Changed to black
                    fontWeight: "normal", // Use normal weight as <strong> will handle bold
                  }}
                >
                  <strong>DR. SIVAKUMAR VEERABATHIRAN</strong> {/* Doctor's name */}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#141414", // Changed to black
                    fontWeight: "normal", // Use normal weight as <strong> will handle bold
                  }}
                >
                  <strong>MBBS., MS., (Ortho) FIJR</strong> {/* Doctor's degrees */}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </div>
        <DialogActions>
          <Button
            variant="gradient"
            color="primary"
            onClick={handleDownload}
            sx={{
              ml: 2,
              color: "#ffff",
              backgroundColor: "#662C90",
              padding: "7px",
              borderRadius: "12px",
              marginRight: "492px",
              "&:hover": {
                backgroundColor: "#5a257e",
              },
            }}
          >
            Download Invoice
          </Button>
          <Button
            onClick={() => setOpenPreviewModal(false)}
            color="primary"
            style={{ color: "#662C90" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer position="top-right" />
    </DashboardLayout>
  );
}

export default Lab;
