import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Grid,
  Typography,
  Backdrop,
} from "@mui/material";
import logoImage from "assets/images/HMS.webp";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { TYPE_C, INVOICE_API_URL } from "api/Api";

const PreviewSummaryPage = () => {
  const location = useLocation();
  const { toDetails } = location.state || {};
  const navigate = useNavigate();
  const [dischargeSummary, setDischargeSummary] = useState(null);
  const [dischargeSummaryOpen, setDischargeSummaryOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (toDetails) {
      setDischargeSummary(toDetails);
    }
  }, [toDetails]);

  const handleDownloadpdf = async () => {
    try {
      setLoading(true);

      const canvas = await html2canvas(document.querySelector("#SummaryCaptured"), {
        scale: 2,
      });

      const imgData = canvas.toDataURL("image/jpeg", 1);

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: "a4",
        compress: true,
      });

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const ratio = Math.min(pdfWidth / imgProps.width, pdfHeight / imgProps.height);
      const imgWidth = imgProps.width * ratio;
      const imgHeight = imgProps.height * ratio;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      const base64PDF = pdf.output("datauristring").split(",")[1];
      const pdfName = `${toDetails.name}_Discharge_Summary.pdf`;

      pdf.save(pdfName);

      const payload = {
        type: TYPE_C,
        filename: pdfName,
        contact: toDetails.patientId,
        uuid: dischargeSummary.id,
        date: dischargeSummary.dischargedDate,
        admission: dischargeSummary.admissionDate,

        total: dischargeSummary.phone,
        name: dischargeSummary.name,

        file: base64PDF,
      };

      try {
        const response = await axios.post(INVOICE_API_URL, payload);
        toast.success("Download completed successfully!");

        if (response.status === 200) {
        } else {
        }
      } catch (error) {}
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <Backdrop open={loading} style={{ zIndex: 1301, backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div
              style={{
                fontSize: "94px",
                color: "#00BFFF",
                marginBottom: "16px",
                animation: "downloadMovement 1s infinite",
              }}
            >
              <i className="material-icons">download</i>
            </div>

            <p style={{ color: "#fff", marginTop: "16px" }}>Downloading, please wait...</p>
          </div>

          <style>
            {`
            @keyframes downloadMovement {
              0% {
                transform: translateY(0); /* Start position */
                opacity: 1; /* Fully visible */
              }
              50% {
                transform: translateY(30px); /* Move down */
                opacity: 0.6; /* Slightly faded */
              }
              100% {
                transform: translateY(60px); /* Move further down */
                opacity: 0; /* Completely faded out */
              }
            }
          `}
          </style>
        </Backdrop>
      </div>
      <Dialog
        open={dischargeSummaryOpen}
        onClose={() => setDischargeSummaryOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <Box>
          <div id="SummaryCaptured">
            <DialogTitle
              sx={{
                textAlign: "left",

                padding: "25px",
                backgroundColor: "#f5f5f5",
                color: "#662C90",
                borderBottom: "1px solid #e0e0e0",
                fontSize: "1.5rem",
                fontWeight: "bold",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 50,
                  marginRight: 2,
                }}
                alt="Veera Logo"
                src={logoImage}
              />
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", color: "#662C90", fontSize: "1.2rem" }}
                >
                  VEERA HOSPITAL
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", fontSize: "0.875rem", color: "#141414" }}
                >
                  Orthopedic Clinic
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", color: "#141414", fontSize: "0.875rem" }}
                >
                  01, Silambani Sannathi Street, inside Arch, Devakottai 630 302
                </Typography>
                <Typography variant="body2" sx={{ color: "#141414", fontSize: "0.875rem" }}>
                  <strong>Email:</strong> veerahospital@gmail.com | <strong>Phone-No:</strong> 98406
                  29905 |<strong> Hours:</strong> Open 24 hours
                </Typography>
              </Box>
            </DialogTitle>

            <DialogContent>
              <Box
                style={{
                  backgroundColor: "#5a257e",
                  padding: "0.1rem",
                  borderRadius: "8px",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#ffff",
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >
                  Discharge Summary
                </Typography>
              </Box>

              {dischargeSummary ? (
                <Box id="SummaryCaptured">
                  <Grid
                    container
                    spacing={5}
                    style={{
                      marginBottom: "15px",
                      justifyContent: "center",
                    }}
                  >
                    <Box pl={3} pr={3}>
                      <Grid container spacing={0.5} mb={1} mt={3}>
                        <Grid item xs={3} textAlign="right">
                          <Typography variant="h6">
                            <strong>ID :</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={3} textAlign="left">
                          <Typography variant="body2">{dischargeSummary.id}</Typography>
                        </Grid>

                        <Grid item xs={3} textAlign="right">
                          <Typography variant="h6">
                            <strong>AdmissionDate :</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={3} textAlign="left">
                          <Typography variant="body2">{dischargeSummary.admissionDate}</Typography>
                        </Grid>

                        <Grid item xs={3} textAlign="right">
                          <Typography variant="h6">
                            <strong>patient Name :</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={3} textAlign="left">
                          <Typography variant="body2">{dischargeSummary.name}</Typography>
                        </Grid>

                        <Grid item xs={3} textAlign="right">
                          <Typography variant="h6">
                            <strong>DateOfSurgery :</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={3} textAlign="left">
                          <Typography variant="body2">{dischargeSummary.dateOfSurgery}</Typography>
                        </Grid>
                        <Grid item xs={3} textAlign="right">
                          <Typography variant="h6">
                            <strong>Phone-No :</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={3} textAlign="left">
                          <Typography variant="body2">{dischargeSummary.phone}</Typography>
                        </Grid>

                        <Grid item xs={3} textAlign="right">
                          <Typography variant="h6">
                            <strong>DischargedDate :</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={3} textAlign="left">
                          <Typography variant="body2">{dischargeSummary.dischargedDate}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Box sx={{ borderBottom: "2px solid #000", marginTop: "20px" }} />

                  <Typography
                    variant="body1"
                    component="div"
                    style={{ whiteSpace: "pre-line", marginBottom: "15px" }}
                  ></Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    style={{ whiteSpace: "pre-line", marginBottom: "15px" }}
                  >
                    {toDetails.consultancy && (
                      <>
                        <strong style={{ color: "black" }}>Consultancy:</strong>{" "}
                        <Typography
                          variant="body1"
                          style={{ marginBottom: "5px", fontSize: "1.275rem" }}
                        >
                          {toDetails.consultancy}
                        </Typography>
                      </>
                    )}

                    {toDetails.diagnosis && (
                      <>
                        <strong style={{ color: "black" }}>Diagnosis:</strong>{" "}
                        <Typography
                          variant="body1"
                          style={{ marginBottom: "5px", fontSize: "1.275rem" }}
                        >
                          {toDetails.diagnosis}
                        </Typography>
                      </>
                    )}

                    {toDetails.present && (
                      <>
                        <strong style={{ color: "black" }}>Presenting Complaints:</strong>{" "}
                        <Typography
                          variant="body1"
                          style={{ marginBottom: "5px", fontSize: "1.275rem" }}
                        >
                          {toDetails.present}
                        </Typography>
                      </>
                    )}

                    {toDetails.pasthistory && (
                      <>
                        <strong style={{ color: "black" }}>Past History:</strong>{" "}
                        <Typography
                          variant="body1"
                          style={{ marginBottom: "5px", fontSize: "1.275rem" }}
                        >
                          {toDetails.pasthistory}
                        </Typography>
                      </>
                    )}

                    {toDetails.procedure && (
                      <>
                        <strong style={{ color: "black" }}>Procedure:</strong>{" "}
                        <Typography
                          variant="body1"
                          style={{ marginBottom: "5px", fontSize: "1.275rem" }}
                        >
                          {toDetails.procedure}
                        </Typography>
                      </>
                    )}

                    {toDetails.localexamination?.trim() && (
                      <>
                        <strong style={{ color: "black" }}>Local Examination:</strong>{" "}
                        <Typography
                          variant="body1"
                          style={{ marginBottom: "5px", fontSize: "1.275rem" }}
                        >
                          {toDetails.localexamination}
                        </Typography>
                      </>
                    )}

                    {toDetails.investigation?.trim() && (
                      <>
                        <strong style={{ color: "black" }}>Investigations:</strong>{" "}
                        <Typography
                          variant="body1"
                          style={{ marginBottom: "5px", fontSize: "1.275rem" }}
                        >
                          {toDetails.investigation}
                        </Typography>
                      </>
                    )}

                    {toDetails.xray?.trim() && (
                      <>
                        <strong style={{ color: "black" }}>X-ray:</strong>{" "}
                        <Typography
                          variant="body1"
                          style={{ marginBottom: "5px", fontSize: "1.275rem" }}
                        >
                          {toDetails.xray}
                        </Typography>
                      </>
                    )}

                    {toDetails.advice_discharge?.trim() && (
                      <>
                        <strong style={{ color: "black" }}>Advice at Discharge:</strong>{" "}
                        <Typography
                          variant="body1"
                          style={{ marginBottom: "5px", fontSize: "1.275rem" }}
                        >
                          {toDetails.advice_discharge}
                        </Typography>
                      </>
                    )}
                  </Typography>
                </Box>
              ) : (
                <Typography variant="body2" color="error">
                  No discharge summary data available.
                </Typography>
              )}
            </DialogContent>
            <DialogContent>
              <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                <Box sx={{ textAlign: "right" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#141414",
                      fontWeight: "normal",
                    }}
                  >
                    <strong>DR. SIVAKUMAR VEERABATHIRAN</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#141414",
                      fontWeight: 450,
                    }}
                  >
                    MBBS., MS., (Ortho) FIJR
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
          </div>
          <Box
            sx={{
              borderBottom: "2px solid #000",
              marginTop: "20px",
            }}
          />

          <Typography
            variant="body1"
            component="div"
            style={{ whiteSpace: "pre-line", marginBottom: "15px" }}
          ></Typography>

          <DialogActions>
            <Button
              variant="gradient"
              color="primary"
              onClick={handleDownloadpdf}
              sx={{
                ml: 2,
                color: "#ffff",
                backgroundColor: "#662C90",
                padding: "12px",
                borderRadius: "16px",
                marginRight: "540px",
                "&:hover": {
                  backgroundColor: "#5a257e",
                },
              }}
            >
              Download
            </Button>

            <Button
              color="primary"
              style={{ color: "#662C90" }}
              onClick={() => {
                setDischargeSummaryOpen(false);
                navigate("/dischargesummary", {
                  state: { admissionDate: dischargeSummary.admissionDate },
                });
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default PreviewSummaryPage;
